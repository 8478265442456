import { Box, Flex } from "@chakra-ui/react";
import { Authorize, Permissions } from "../../permissions";
import ContentTopBar from "../../shared/ContentTopBar";
import useTranslate from "../../locale/hooks/useTranslate";
import EditSystemMessage from "../systemMessage/EditSystemMessage";

const CreateSystemMessagePage = () => {
  const translate = useTranslate();

  return (
    <Authorize require={[Permissions.CREATE_SYSTEM_MESSAGE]} redirectOnDenyTo="/dashboard">
      <Box h="full" display="flex" flexDirection="column">
        <ContentTopBar showBackButton pageTitle={translate("system_messages.system_message")} />
        <Flex layerStyle={"mainContent"} flex="1">
          <EditSystemMessage />
        </Flex>
      </Box>
    </Authorize>
  );
};

export default CreateSystemMessagePage;

import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { removeObjectBlankAttributes } from "../shared/DataFilters/remove_blank_attributes";

const extractSearchParams = (searchParams: URLSearchParams) => {
  const newState: any = {};
  searchParams.forEach((value, key) => {
    if (!newState[key]) {
      newState[key] = value;
    } else {
      if (!Array.isArray(newState[key])) {
        newState[key] = [newState[key]];
      }
      newState[key].push(value);
    }
  });
  return newState;
};

//Note, if you use this hook with controlled inputs, please use the ControlledInputs components in the src/shared folder
const useUrlState = (defaultState: any = {}, filterBlank = false) => {
  const [searchParams, setSearchParams] = useSearchParams(removeObjectBlankAttributes(defaultState));
  const setParams = (params: URLSearchParams) => {
    setSearchParams(removeObjectBlankAttributes(params));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const state = useMemo(() => ({ ...defaultState, ...extractSearchParams(searchParams) }), [searchParams]);
  return [state, setParams];
};

export default useUrlState;

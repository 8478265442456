import React from "react";
import { useNavigate } from "react-router-dom";
import { IPermission } from "./types";
import useHasPermissions from "./hooks/useHasPermissions";

type Props = {
  children: React.ReactElement;
  require: IPermission[];
  requireAll?: boolean;
  redirectOnDenyTo?: string | null;
};

const Authorize = ({
  children,
  require = [],
  requireAll = true,
  redirectOnDenyTo = null,
}: Props) => {
  const navigate = useNavigate();
  const [isLoading, hasPermission] = useHasPermissions(require, requireAll);

  if (isLoading) {
    return null;
  }
  if (!isLoading! && hasPermission) {
    return children;
  }
  if (!hasPermission && redirectOnDenyTo) {
    navigate(redirectOnDenyTo);
  }
  return null;
};

export default Authorize;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IEmailTemplate,
  IGetEmailDetailsPayload,
  IGetEmailDetailsResponse,
  IGetEmailsPayload,
  IGetEmailsResponse,
  IPreviewEmailTemplatePayload,
  ISendEmailTemplatePayload,
} from "./types";

export const emailTemplatesApi = createApi({
  reducerPath: "emailTemplatesApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    getEmailTemplates: builder.query<{ templates: IEmailTemplate[] }, { accessToken: string; language: string }>({
      query: payload => {
        const { accessToken } = payload;
        return {
          url: `/v1/email_template`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    previewTemplateEmail: builder.mutation<{ html: string }, IPreviewEmailTemplatePayload>({
      query: payload => {
        const { accessToken, ...body } = payload;
        return {
          url: "/v1/email_template/preview",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    sendEmailTemplate: builder.mutation<{ sent: boolean }, ISendEmailTemplatePayload>({
      query: payload => {
        const { accessToken, ...body } = payload;
        return {
          url: "/v1/email_template/send",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getEmails: builder.query<IGetEmailsResponse, IGetEmailsPayload>({
      query: payload => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/tracked_email`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getEmailDetails: builder.query<IGetEmailDetailsResponse, IGetEmailDetailsPayload>({
      query: payload => {
        const { accessToken, email_id } = payload;
        return {
          url: `/v1/tracked_email/${email_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetEmailTemplatesQuery,
  usePreviewTemplateEmailMutation,
  useSendEmailTemplateMutation,
  useGetEmailsQuery,
  useGetEmailDetailsQuery,
} = emailTemplatesApi;


import markerSDK from '@marker.io/browser';
import { MARKER_IO_PROJECT } from '../config';
declare global {
    interface Window { MarkerIoWidgetIntegration: any; }
}

window.MarkerIoWidgetIntegration = { 'isSet': false }
const loadMakerWidget = (email: string, name: string) => {
    if (!MARKER_IO_PROJECT) return;

    if (window.MarkerIoWidgetIntegration['isSet']) {
        return;
    }
    markerSDK.loadWidget({
        project: MARKER_IO_PROJECT,
        reporter: {
            email: email,
            fullName: name
        },
    })

    window.MarkerIoWidgetIntegration['isSet'] = true;



};

export default loadMakerWidget;
import { EnrichedEntity, IEntityType } from "./types";

enum Entities {
  USER = "internal_user",
  EXTERNAL_USER = "external_user",
  VEHICLE = "vehicle",
  FILE = "file",
  CONTACT = "legal_entity",
  DEALER_CREDIT = "dealer_credit",
  DEALER_ACCOUNT = "dealer_account",
  DEAL = "deal",
  VEHICLE_SITE_AUDIT = "vehicle_site_audit",
  KYC_APPLICATION = "kyc_application",
  INVOICE = "invoice",
}

const EntityMapping = new Map<IEntityType, EnrichedEntity>([
  [
    Entities.INVOICE,
    {
      url: "/invoice/",
      icon: "receipt",
    },
  ],
  [
    Entities.VEHICLE,
    {
      url: "/vehicle/",
      icon: "directions_car",
      revision_query_param: "vehicle_rev_id",
    },
  ],
  [
    Entities.USER,
    {
      url: "/user-management/",
      icon: "person",
    },
  ],
  [
    Entities.EXTERNAL_USER,
    {
      url: "/external-user/",
      icon: "photo_camera_front",
    },
  ],
  [
    Entities.CONTACT,
    {
      url: "/contact/",
      icon: "contacts",
      revision_query_param: "contact_rev_id",
    },
  ],
  [
    Entities.FILE,
    {
      url: "/file/",
      icon: "description",
      revision_query_param: "file_rev_id",
    },
  ],
  [
    Entities.DEALER_CREDIT,
    {
      url: "/dealer-credit/",
      icon: "account_balance_wallet",
    },
  ],
  [
    Entities.DEALER_ACCOUNT,
    {
      url: "/dealer-account/",
      icon: "corporate_fare",
      revision_query_param: "dealer_account_rev_id",
    },
  ],
  [
    Entities.DEAL,
    {
      url: "/deal/",
      icon: "emoji_transportation",
      revision_query_param: "deal_rev_id",
    },
  ],
  [
    Entities.VEHICLE_SITE_AUDIT,
    {
      url: "/site-audit/",
      icon: "no_crash",
      revision_query_param: "audit_rev_id",
    },
  ],
  [
    Entities.KYC_APPLICATION,
    {
      url: "/kyc/",
      icon: "business_center",
    },
  ],
]);

export { EntityMapping, Entities };

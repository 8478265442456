import { useContext, useEffect, useState } from "react";
import { Flex, Image, Box, Link as ChakraLink, Text, IconButton } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import Icon from "../shared/Icon";
import useTranslate from "../locale/hooks/useTranslate";
import HfTenantKeySelector from "../account/HfTenantKeySelector";
import { useGetSessionQuery } from "../account/service";
import { AccessTokenContext } from "../auth/ProtectedComponent";
import SidebarMenuSections from "./sidebar/SidebarMenuSections";
import SidebarAccordionSections from "./sidebar/SidebarAccordionSections";

type Props = {
  isAccordionMenu?: boolean;
};

const Sidebar = ({ isAccordionMenu }: Props) => {
  const translate = useTranslate();

  const accessToken = useContext(AccessTokenContext);
  const { data: session } = useGetSessionQuery({ accessToken });

  const [isSidebarLarge, setIsSidebarLarge] = useState<boolean>(
    isAccordionMenu !== undefined ? isAccordionMenu : false
  );

  useEffect(() => {
    if (isAccordionMenu) {
      setIsSidebarLarge(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccordionMenu]);

  return (
    <Box as="nav" aria-label="Sidebar Navigation" layerStyle={isSidebarLarge ? "sidebarNav" : "sidebarNavSmall"}>
      <Flex flexDir="column" h="100%" bgColor="purple.800">
        {session?.environment_id !== "production" && (
          <Box bg="greens.800" color="white" py="1" flex="0 0 auto" minH="0">
            <Text textAlign="center" textTransform="uppercase" fontWeight="bold">
              {session?.environment_id === "local" && translate("placeholders.local_mode")}
              {session?.environment_id !== "local" && translate("placeholders.dev_mode")}
            </Text>
          </Box>
        )}

        <Box layerStyle="sidebarLogo" flex="0 0 auto" minH="0">
          <ChakraLink as={ReactRouterLink} to="/dashboard" variant="inherit">
            <Image
              w={isSidebarLarge ? "full" : "10"}
              mx="auto"
              cursor="pointer"
              src={isSidebarLarge ? "/images/logo-white.svg" : "/images/logo-square-white.svg"}
              alt="Handlarfinans logo"
            />
          </ChakraLink>
        </Box>

        <Box mt="4" flex="0 0 auto" minH="0" px="3">
          <HfTenantKeySelector />
        </Box>

        <Box layerStyle="sidebarMenuWrapper" flex="1 1 auto" minH="0">
          {isSidebarLarge ? <SidebarAccordionSections /> : <SidebarMenuSections />}
        </Box>
      </Flex>

      <IconButton
        display={["none", null, null, "flex"]}
        icon={<Icon name={isSidebarLarge ? "navigate_before" : "navigate_next"} />}
        aria-label="switch sidebar size"
        variant="sidebarToggle"
        onClick={() => setIsSidebarLarge(!isSidebarLarge)}
      />
    </Box>
  );
};

export default Sidebar;

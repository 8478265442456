import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetPaymentsPayload,
  IGetPaymentsResponse,
  IChangePaymentPayload,
  IAddPaymentPayload,
  IDeletePaymentPayload,
} from "./types";

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["ArosPayments"],
  endpoints: builder => ({
    getPayments: builder.query<IGetPaymentsResponse, IGetPaymentsPayload>({
      query: payload => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: "/v1/payment",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["ArosPayments"],
    }),
    updatePayment: builder.mutation<{ updated: boolean }, IChangePaymentPayload>({
      query: ({ accessToken, transaction_id, status, provider }) => {
        return {
          url: `/v1/payment/${transaction_id}`,
          method: "PUT",
          body: { status, provider },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["ArosPayments"],
    }),
    addPayment: builder.mutation<{ updated: boolean }, IAddPaymentPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/payment",
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["ArosPayments"],
    }),
    deletePayment: builder.mutation<{ updated: boolean }, IDeletePaymentPayload>({
      query: ({ accessToken, transaction_id }) => {
        return {
          url: `/v1/payment/${transaction_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["ArosPayments"],
    }),
  }),
});

export const { useGetPaymentsQuery, useUpdatePaymentMutation, useAddPaymentMutation, useDeletePaymentMutation } =
  paymentsApi;

import React from "react";

import { useGetSessionQuery } from "../account/service";
import { HF_tenant_key } from "../dealerAccounts/types";
import {useSearchParams} from "react-router-dom";
import {BASE_URL} from "../config";

type Props = {
  children: JSX.Element;
};

const AccessTokenContext = React.createContext("");
const TenantContext = React.createContext<HF_tenant_key>("hf_fi");

const LocallyProtectedComponent = ({ children }: Props) => {
  const [searchParams] = useSearchParams();
  const  hf_tenant_key = searchParams.get('hf_tenant_key')

  const accessToken = localStorage.getItem("local_auth_access_token") || ""
  if (accessToken === ""){
    window.location.href = BASE_URL
  }
  const { data: torqueSession } = useGetSessionQuery({ accessToken }, { skip: !accessToken });

  const selectedTenant = hf_tenant_key as HF_tenant_key || (torqueSession?.selected_tenant as HF_tenant_key) || null;


  return (
    <>
      {accessToken && selectedTenant && (
        <AccessTokenContext.Provider value={accessToken}>
          <TenantContext.Provider value={selectedTenant}>
            {children}
          </TenantContext.Provider>
        </AccessTokenContext.Provider>
      )}
    </>
  );
};

export { AccessTokenContext, TenantContext };
export default LocallyProtectedComponent;

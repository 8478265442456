import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetRepossessionsPayload,
  IGetRepossessionsResponse,
  IGetRepossessionPayload,
  IGetRepossessionResponse,
  ICreateRepossessionPayload,
  ICreateRepossessionResponse,
  IDeleteRepossessionPayload,
  IDeleteRepossessionResponse,
  IUpdateRepossessionPayload,
  IUpdateRepossessionResponse,
  IExportRepossessionsResponse,
  IExportRepossessionsPayload,
  IUpdateProcessStepResponse,
  IUpdateProcessStepPayload,
} from "./types";

export const repossessionsApi = createApi({
  reducerPath: "repossessionsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Repossessions", "Repossession"],
  endpoints: builder => ({
    getRepossessions: builder.query<IGetRepossessionsResponse, IGetRepossessionsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/repossession`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Repossessions"],
    }),
    exportRepossessions: builder.mutation<IExportRepossessionsResponse, IExportRepossessionsPayload>({
      query: ({ accessToken, filename, ...queryParams }) => {
        return {
          url: `/v1/repossession_export`,
          method: "POST",
          body: { filename },
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getRepossession: builder.query<IGetRepossessionResponse, IGetRepossessionPayload>({
      query: ({ accessToken, repossession_id }) => {
        return {
          url: `/v1/repossession/${repossession_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Repossession"],
    }),
    createRepossession: builder.mutation<ICreateRepossessionResponse, ICreateRepossessionPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/repossession`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Repossessions"],
    }),
    updateRepossession: builder.mutation<IUpdateRepossessionResponse, IUpdateRepossessionPayload>({
      query: ({ accessToken, repossession_id, ...payload }) => {
        return {
          url: `/v1/repossession/${repossession_id}`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Repossessions", "Repossession"],
    }),
    deleteRepossession: builder.mutation<IDeleteRepossessionResponse, IDeleteRepossessionPayload>({
      query: ({ accessToken, repossession_id }) => {
        return {
          url: `/v1/repossession/${repossession_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Repossessions"],
    }),
    updateProcessStep: builder.mutation<IUpdateProcessStepResponse, IUpdateProcessStepPayload>({
      query: ({ accessToken, repossession_id, process_step }) => {
        return {
          url: `/v1/repossession/${repossession_id}/process_step`,
          method: "PUT",
          body: { process_step },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Repossession"],
    }),
  }),
});

export const {
  useGetRepossessionsQuery,
  useExportRepossessionsMutation,
  useGetRepossessionQuery,
  useCreateRepossessionMutation,
  useDeleteRepossessionMutation,
  useUpdateRepossessionMutation,
  useUpdateProcessStepMutation,
} = repossessionsApi;

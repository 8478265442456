import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetSessionQuery } from "../account/service";
import { HF_tenant_key } from "../dealerAccounts/types";
import useUrlState from "../hooks/useUrlState";
import LocallyProtectedComponent from "./LocallyProtectedComponent";
import { RUN_LOCALLY } from "../config";
import { AccessTokenContext as LocalAccessTokenContext } from "./LocallyProtectedComponent";
import { TenantContext as LocalTenantContext } from "./LocallyProtectedComponent";

type Props = {
  children: JSX.Element;
};

let AccessTokenContext = React.createContext("");
let TenantContext = React.createContext<HF_tenant_key>("hf_fi");

const Auth0ProtectedComponent = ({ children }: Props) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const [urlState] = useUrlState({});
  const hf_tenant_key = urlState.hf_tenant_key;

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (e) {
        const redirectTarget = `${window.location.pathname}${window.location.search}`;
        loginWithRedirect({ appState: { target: redirectTarget } });
      }
    };
    getAccessToken();
  }, [getAccessTokenSilently, setAccessToken, loginWithRedirect]);

  const { data: torqueSession } = useGetSessionQuery({ accessToken }, { skip: !accessToken });

  const selectedTenant = (hf_tenant_key as HF_tenant_key) || (torqueSession?.selected_tenant as HF_tenant_key) || null;

  return (
    <>
      {accessToken && selectedTenant && (
        <AccessTokenContext.Provider value={accessToken}>
          <TenantContext.Provider value={selectedTenant}>
            {children}
          </TenantContext.Provider>
        </AccessTokenContext.Provider>
      )}
    </>
  );
};

let defaultExportedComponent = Auth0ProtectedComponent;
if (RUN_LOCALLY) {
  defaultExportedComponent = LocallyProtectedComponent;
  AccessTokenContext = LocalAccessTokenContext;
  TenantContext = LocalTenantContext;
}
export { AccessTokenContext, TenantContext };

export default defaultExportedComponent;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import { IGetDataResponse, IGetDataPayload } from "./types";

export const fragusDecoderApi = createApi({
  reducerPath: "fragusDecoderApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getDataByRegistrationNumber: builder.mutation<IGetDataResponse, IGetDataPayload>({
      query: ({ accessToken, reg_no }) => {
        return {
          url: `/v1/integration/fragus/vehicle_import`,
          method: "POST",
          params: { reg_no: reg_no },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetDataByRegistrationNumberMutation } = fragusDecoderApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
    IDiffLogsResponse,
    IDiffLogsFetchPayload,
    ISchedulesResponse,
    ISchedulesFetchPayload,
    IMonitoringSourcesResponse,
    IMonitoringSourcesFetchPayload,
    IMonitoringCheckResponse,
    IMonitoringCheckFetchPayload,
    IDeleteScheduleResponse,
    IDeleteSchedulePayload,
    IScheduleResponse,
    IScheduleFetchPayload,
    ICreateMonitoringScheduleResponse,
    ICreateMonitoringSchedulePayload,
    IDiffLogFetchPayload,
    IDiffLogResponse
} from "./types";

export const monitoringApi = createApi({
    reducerPath: "monitoringApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
    tagTypes: ["Observations", "Schedules"],
    endpoints: (builder) => ({
        getMonitoringSchedules: builder.query<ISchedulesResponse, ISchedulesFetchPayload>({
            query: (payload) => {
                const { accessToken, ...query } = payload;
                return {
                    url: `/v1/monitoring/schedule_entry`,
                    method: "GET",
                    params: query,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["Schedules"],
        }),
        getMonitoringSchedule: builder.query<IScheduleResponse, IScheduleFetchPayload>({
            query: (payload) => {
                const { accessToken, schedule_id } = payload;
                return {
                    url: `/v1/monitoring/schedule_entry/${schedule_id}`,
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["Schedules"],
        }),
        runMonitorScheduleCheck: builder.mutation<IMonitoringCheckResponse, IMonitoringCheckFetchPayload>({
            query: (payload) => {
                const { accessToken, schedule_id } = payload;
                return {
                    url: `/v1/monitoring/schedule_entry/${schedule_id}/run`,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            invalidatesTags: ["Schedules"],
        }),
        getMonitoringSources: builder.query<IMonitoringSourcesResponse, IMonitoringSourcesFetchPayload>({
            query: (payload) => {
                const { accessToken, ...query } = payload;
                return {
                    url: `/v1/monitoring/source`,
                    method: "GET",
                    params: query,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            }
        }),
        addMonitoringSchedule: builder.mutation<ICreateMonitoringScheduleResponse, ICreateMonitoringSchedulePayload>({
            query: ({ accessToken, ...body }) => {
                return {
                    url: `/v1/monitoring/schedule_entry`,
                    method: "POST",
                    body: body,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            invalidatesTags: ["Schedules"],
        }),
        deleteMonitoringSchedule: builder.mutation<IDeleteScheduleResponse, IDeleteSchedulePayload>({
            query: ({ accessToken, schedule_id }) => {
                return {
                    url: `/v1/monitoring/schedule_entry/${schedule_id}`,
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            invalidatesTags: ["Schedules", "Observations"]
        }),
        getDiffLogs: builder.query<IDiffLogsResponse, IDiffLogsFetchPayload>({
            query: (payload) => {
                const { accessToken, ...query } = payload;
                return {
                    url: `/v1/monitoring/diff_log`,
                    method: "GET",
                    params: query,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["Observations"]
        }),
        getDiffLog: builder.query<IDiffLogResponse, IDiffLogFetchPayload>({
            query: (payload) => {
                const { accessToken, entry_id } = payload;
                return {
                    url: `/v1/monitoring/diff_log/${entry_id}`,
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["Observations"]
        }),
    })
})

export const {
    useGetDiffLogsQuery,
    useGetMonitoringSchedulesQuery,
    useGetMonitoringSourcesQuery,
    useDeleteMonitoringScheduleMutation,
    useGetMonitoringScheduleQuery,
    useAddMonitoringScheduleMutation,
    useRunMonitorScheduleCheckMutation,
    useGetDiffLogQuery
} = monitoringApi;


import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { FormControl, FormLabel, Select, SimpleGrid, Switch, useToast } from "@chakra-ui/react";
import { useGetSessionQuery } from "../../account/service";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import useTranslate from "../../locale/hooks/useTranslate";
import AutosizeTextarea from "../../shared/AutosizeTextarea";
import { DebouncedInput } from "../../shared/ControlledInputs";
import MultipleSelect from "../../shared/ControlledInputs/MultipleSelect";
import { formatLocalDateTimeToUTC } from "../../shared/formatters/DateTimeFormatter";
import { convertUtcIsoDateTimeToLocalIsoDateTime } from "../../shared/formatters/LocalDateTime";
import { IFormSystemMessage, LanguageId, SystemMessageSeverity } from "../types";
import { IFeatureFlag } from "../../dealerAccounts/types";
import { Permissions } from "../../permissions";

type Props = {
  message?: IFormSystemMessage;
  user_permissions: Permissions[];
  feature_flags: IFeatureFlag[];
  submitRef?: React.Ref<any> | undefined;
  onSubmit: (message: IFormSystemMessage) => void;
};

export default function SystemMessageForm({ submitRef, message, onSubmit, feature_flags, user_permissions }: Props) {
  useImperativeHandle(submitRef, () => ({ submitForm: handleSubmit }));
  const toast = useToast();
  const accessToken = useContext(AccessTokenContext);
  const { data: userSession } = useGetSessionQuery({ accessToken });
  const tenants = userSession?.visible_tenants || [];

  const translate = useTranslate();
  const [messageData, setMessage] = useState<IFormSystemMessage>({ texts: [], enabled: false });

  useEffect(() => {
    if (message) {
      setMessage({
        ...message,
        valid_from: message.valid_from ? convertUtcIsoDateTimeToLocalIsoDateTime(message.valid_from) : "",
        valid_to: message.valid_to ? convertUtcIsoDateTimeToLocalIsoDateTime(message.valid_to) : "",
      });
    }
  }, [message]);

  const updateMessage = (key: string, value: any) => {
    setMessage((prevMessage) => {
      return { ...prevMessage, [key]: value };
    });
  };

  const updateTextMessage = (key: LanguageId, value: any) => {
    setMessage((prevMessage) => {
      let isNewText = true;
      const texts = prevMessage.texts.map((text) => {
        if (text.language_id === key) {
          isNewText = false;
          return { ...text, text: value };
        }
        return text;
      });
      if (isNewText) {
        texts.push({ language_id: key, text: value });
      }
      return { ...prevMessage, texts };
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (!messageData.severity) {
      toast({
        title: translate("system_messages.severity_required"),
        status: "error",
      });
      return;
    }

    const filteredTexts = messageData.texts.filter((text) => text.text && text.text.trim().length > 0);
    if (!filteredTexts.length) {
      toast({
        title: translate("system_messages.validation_error"),
        status: "error",
      });
      return;
    }

    onSubmit({
      ...messageData,
      valid_from: messageData.valid_from ? formatLocalDateTimeToUTC(messageData.valid_from) : "",
      valid_to: messageData.valid_to ? formatLocalDateTimeToUTC(messageData.valid_to) : "",
      texts: filteredTexts,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="4">
        <FormControl>
          <FormLabel>{translate("system_messages.tenant")}</FormLabel>
          <MultipleSelect
            onChange={(key, value) => updateMessage(key, value)}
            value={messageData?.hf_tenant_keys}
            options={tenants}
            name="hf_tenant_keys"
            inputText={translate("system_messages.tenant")}
            optionsTranslationPath="hf_tenant_key.country"
          />
        </FormControl>
        <FormControl>
          <FormLabel>{translate("system_messages.severity")}</FormLabel>
          <Select
            placeholder={translate("placeholders.none")}
            isRequired
            flexShrink="0"
            name="severity"
            value={messageData?.severity}
            onChange={(e) => updateMessage("severity", e.target.value || undefined)}
          >
            {Object.values(SystemMessageSeverity).map((severity) => (
              <option key={severity} value={severity}>
                {translate(`system_messages.severity_options.${severity}`)}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>{translate("system_messages.valid_from")}</FormLabel>
          <DebouncedInput
            type="datetime-local"
            name="valid_from"
            max="9999-12-31T00:00:00"
            step="1"
            value={messageData.valid_from || ""}
            onChange={(value) => updateMessage("valid_from", value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{translate("system_messages.valid_to")}</FormLabel>
          <DebouncedInput
            type="datetime-local"
            name="valid_to"
            max="9999-12-31T00:00:00"
            step="1"
            value={messageData.valid_to || ""}
            onChange={(value) => updateMessage("valid_to", value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{translate("system_messages.feature_flag")}</FormLabel>
          <Select
            placeholder={translate("placeholders.none")}
            isRequired
            flexShrink="0"
            name="severity"
            value={messageData?.feature_flag || undefined}
            onChange={(e) => updateMessage("feature_flag", e.target.value || undefined)}
          >
            {feature_flags.map((feature) => (
              <option key={feature} value={feature}>
                {translate(`dealer_account.features.${feature}`)}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>{translate("system_messages.permission")}</FormLabel>
          <Select
            placeholder={translate("placeholders.none")}
            isRequired
            flexShrink="0"
            name="severity"
            value={messageData?.permission || undefined}
            onChange={(e) => updateMessage("permission", e.target.value || undefined)}
          >
            {user_permissions.map((permission) => (
              <option key={permission} value={permission}>
                {permission}
              </option>
            ))}
          </Select>
        </FormControl>
      </SimpleGrid>

      <FormControl mt="4">
        <FormLabel>{translate("system_messages.en_text")}</FormLabel>
        <AutosizeTextarea
          value={messageData.texts.find((text) => text.language_id === LanguageId.EN)?.text}
          onChange={(e) => updateTextMessage(LanguageId.EN, e.target.value)}
          maxRows={6}
          placeholder={translate("system_messages.message")}
        />
      </FormControl>

      <FormControl mt="4">
        <FormLabel>{translate("system_messages.se_text")}</FormLabel>
        <AutosizeTextarea
          value={messageData.texts.find((text) => text.language_id === LanguageId.SE)?.text}
          onChange={(e) => updateTextMessage(LanguageId.SE, e.target.value)}
          maxRows={6}
          placeholder={translate("system_messages.message")}
        />
      </FormControl>

      <FormControl mt="4">
        <FormLabel>{translate("system_messages.fi_text")}</FormLabel>
        <AutosizeTextarea
          value={messageData.texts.find((text) => text.language_id === LanguageId.FI)?.text}
          onChange={(e) => updateTextMessage(LanguageId.FI, e.target.value)}
          maxRows={6}
          placeholder={translate("system_messages.message")}
        />
      </FormControl>

      <FormControl display="flex" alignItems="center" gridColumn="span 2" mt="4">
        <FormLabel cursor="pointer">{translate("system_messages.enable_message")}</FormLabel>
        <Switch isChecked={messageData?.enabled} onChange={(e) => updateMessage("enabled", e.target.checked)} />
      </FormControl>
    </form>
  );
}

import { Box, Text, Flex } from "@chakra-ui/react";
import { SidebarItem } from "../../shared/sidebar/sidebarItems";
import Icon from "../../shared/Icon";
import useTranslate from "../../locale/hooks/useTranslate";
type Props = {
  item: SidebarItem;
  focused: boolean;
  onItemClicked: (result: SidebarItem) => void;
};

const SidebarItemsSearchResult = ({ item, focused, onItemClicked }: Props) => {
  const translate = useTranslate();

  const focusedStyle = focused ? { bg: "var(--chakra-colors-grayLight-500)" } : {};
  return (
    <Box
      onClick={_ => {
        onItemClicked(item);
      }}
      layerStyle="inputResultsListItem"
      {...focusedStyle}>
      <Flex alignItems="center" fontWeight="semibold" gap="1">
        <Icon name="link" />
        <Text>{item.label}</Text>
      </Flex>

      <Flex alignItems="center" color="grayMid.500" textStyle="size-md" gap="1">
        <Text>{translate("placeholders.path")}:</Text>
        <Text noOfLines={1}>{item.path}</Text>
      </Flex>
    </Box>
  );
};

export default SidebarItemsSearchResult;

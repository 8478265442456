import React from "react";
import { IDomainEntitySearchResult } from "./types";
import { Box, Text, Flex } from "@chakra-ui/react";
import EntityIcon from "./EntityIcon";
type Props = {
  result: IDomainEntitySearchResult;
  focused: boolean;
  onResultClicked: (result: IDomainEntitySearchResult) => void;
};

const DomainEntitySearchResult = ({ result, focused, onResultClicked }: Props) => {
  const focusedStyle = focused ? { bg: "var(--chakra-colors-grayLight-500)" } : {};
  const { entity_type, entity_title, property_key, property_value } = result;
  return (
    <Box
      onClick={(_) => {
        onResultClicked(result);
      }}
      layerStyle={["inputResultsListItem"]}
      {...focusedStyle}
    >
      <Flex alignItems="center" fontWeight="semibold">
        <EntityIcon entityType={entity_type} />
        <Text>{entity_title}</Text>
      </Flex>
      {property_key && property_value && (
        <Flex alignItems="center" color="grayMid.500" textStyle="size-md">
          <Text mr="1">{property_key}:</Text>
          <Text noOfLines={1}>{property_value}</Text>
        </Flex>
      )}
    </Box>
  );
};

export default DomainEntitySearchResult;

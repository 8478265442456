import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateAssertionPayload,
  ICreateAssertionResponse,
  IDeleteAssertionPayload,
  IDeleteAssertionResponse,
  IFetchAssertionPayload,
  IFetchAssertionResponse,
  IFetchAssertionsPayload,
  IFetchAssertionsResponse,
} from "./types";

export const assertionsApi = createApi({
  reducerPath: "assertionsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Assertions"],
  endpoints: (builder) => ({
    getAssertions: builder.query<IFetchAssertionsResponse, IFetchAssertionsPayload>({
      query: ({ accessToken, ...params }) => {
        return {
          url: "/v1/assertion",
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Assertions"],
    }),
    getAssertion: builder.query<IFetchAssertionResponse, IFetchAssertionPayload>({
      query: ({ accessToken, assertion_id, ...params }) => {
        return {
          url: `/v1/assertion/${assertion_id}`,
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Assertions"],
    }),
    deleteAssertion: builder.mutation<IDeleteAssertionResponse, IDeleteAssertionPayload>({
      query: ({ accessToken, assertion_id }) => {
        return {
          url: `/v1/assertion/${assertion_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Assertions"],
    }),
    createAssertion: builder.mutation<ICreateAssertionResponse, ICreateAssertionPayload>({
      query: ({ accessToken, ...body }) => {
        return {
          url: "/v1/assertion",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Assertions"],
    }),
  }),
});

export const { useGetAssertionsQuery, useGetAssertionQuery, useDeleteAssertionMutation, useCreateAssertionMutation } =
  assertionsApi;

import { useContext, useMemo } from "react";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import { createNotificationTree } from "../notifications/notifications_structure";
import { useFetchNotificationTreeQuery } from "../service";

export default function useGetNotificationTree() {
  const accessToken = useContext(AccessTokenContext);

  const { data: notification_tree, isLoading } = useFetchNotificationTreeQuery({ accessToken });
  const schema = useMemo(() => {
    if (!notification_tree || Object.keys(notification_tree).length === 0) return;
    return createNotificationTree(notification_tree);
  }, [notification_tree]);
  return { schema, isLoading };
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import { IGetDataByVINResponse, IGetDataByVINPayload } from "./types";

export const vinDecoderApi = createApi({
  reducerPath: "vinDecoderApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getDataByVIN: builder.query<IGetDataByVINResponse, IGetDataByVINPayload>({
      query: ({ accessToken, vin }) => {
        return {
          url: "/v1/integration/vindecoder/info",
          method: "GET",
          params: { vin: vin },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetDataByVINQuery } = vinDecoderApi;

import { Box, Center } from "@chakra-ui/react";
import React from "react";

import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { ReactComponent as ArrowDropDown } from "@material-design-icons/svg/outlined/arrow_drop_down.svg";
import { ReactComponent as ArrowRightAlt } from "@material-design-icons/svg/outlined/arrow_right_alt.svg";
import { ReactComponent as Autorenew } from "@material-design-icons/svg/outlined/autorenew.svg";
import { ReactComponent as Chat } from "@material-design-icons/svg/outlined/chat.svg";
import { ReactComponent as ChevronLeft } from "@material-design-icons/svg/outlined/chevron_left.svg";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as CloudUpload } from "@material-design-icons/svg/outlined/cloud_upload.svg";
import { ReactComponent as CompareArrows } from "@material-design-icons/svg/outlined/compare_arrows.svg";
import { ReactComponent as Contacts } from "@material-design-icons/svg/outlined/contacts.svg";
import { ReactComponent as ContentCopy } from "@material-design-icons/svg/outlined/content_copy.svg";
import { ReactComponent as CorporateFare } from "@material-design-icons/svg/outlined/corporate_fare.svg";
import { ReactComponent as Delete } from "@material-design-icons/svg/outlined/delete.svg";
import { ReactComponent as Description } from "@material-design-icons/svg/outlined/description.svg";
import { ReactComponent as DirectionsCar } from "@material-design-icons/svg/outlined/directions_car.svg";
import { ReactComponent as DoNotDisturb } from "@material-design-icons/svg/outlined/do_not_disturb.svg";
import { ReactComponent as Done } from "@material-design-icons/svg/outlined/done.svg";
import { ReactComponent as Email } from "@material-design-icons/svg/outlined/email.svg";
import { ReactComponent as Event } from "@material-design-icons/svg/outlined/event.svg";
import { ReactComponent as ExpandMore } from "@material-design-icons/svg/outlined/expand_more.svg";
import { ReactComponent as ExpandLess } from "@material-design-icons/svg/outlined/expand_less.svg";
import { ReactComponent as Garage } from "@material-design-icons/svg/outlined/garage.svg";
import { ReactComponent as Group } from "@material-design-icons/svg/outlined/group.svg";
import { ReactComponent as Groups } from "@material-design-icons/svg/outlined/groups.svg";
import { ReactComponent as Handshake } from "@material-design-icons/svg/outlined/handshake.svg";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import { ReactComponent as Key } from "@material-design-icons/svg/outlined/key.svg";
import { ReactComponent as Logout } from "@material-design-icons/svg/outlined/logout.svg";
import { ReactComponent as ManageAccounts } from "@material-design-icons/svg/outlined/manage_accounts.svg";
import { ReactComponent as ManageSearch } from "@material-design-icons/svg/outlined/manage_search.svg";
import { ReactComponent as Map } from "@material-design-icons/svg/outlined/map.svg";
import { ReactComponent as Menu } from "@material-design-icons/svg/outlined/menu.svg";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/outlined/more_vert.svg";
import { ReactComponent as NewLabel } from "@material-design-icons/svg/outlined/new_label.svg";
import { ReactComponent as NoCrash } from "@material-design-icons/svg/outlined/no_crash.svg";
import { ReactComponent as Payments } from "@material-design-icons/svg/outlined/payments.svg";
import { ReactComponent as PictureAsPdf } from "@material-design-icons/svg/outlined/picture_as_pdf.svg";
import { ReactComponent as PriorityHigh } from "@material-design-icons/svg/outlined/priority_high.svg";
import { ReactComponent as PublishedWithChanges } from "@material-design-icons/svg/outlined/published_with_changes.svg";
import { ReactComponent as ReceiptLong } from "@material-design-icons/svg/outlined/receipt_long.svg";
import { ReactComponent as Receipt } from "@material-design-icons/svg/outlined/receipt.svg";
import { ReactComponent as Remove } from "@material-design-icons/svg/outlined/remove.svg";
import { ReactComponent as Report } from "@material-design-icons/svg/outlined/report.svg";
import { ReactComponent as Schedule } from "@material-design-icons/svg/outlined/schedule.svg";
import { ReactComponent as Send } from "@material-design-icons/svg/outlined/send.svg";
import { ReactComponent as Settings } from "@material-design-icons/svg/outlined/settings.svg";
import { ReactComponent as StickyNote2 } from "@material-design-icons/svg/outlined/sticky_note_2.svg";
import { ReactComponent as SubdirectoryArrowLeft } from "@material-design-icons/svg/outlined/subdirectory_arrow_left.svg";
import { ReactComponent as Flag } from "@material-design-icons/svg/outlined/flag.svg";
import { ReactComponent as TaskAlt } from "@material-design-icons/svg/outlined/task_alt.svg";
import { ReactComponent as Today } from "@material-design-icons/svg/outlined/today.svg";
import { ReactComponent as Visibility } from "@material-design-icons/svg/outlined/visibility.svg";
import { ReactComponent as Warning } from "@material-design-icons/svg/outlined/warning.svg";
import { ReactComponent as WarningAmber } from "@material-design-icons/svg/outlined/warning_amber.svg";
import { ReactComponent as EmojiTransportation } from "@material-design-icons/svg/outlined/emoji_transportation.svg";
import { ReactComponent as ListAlt } from "@material-design-icons/svg/outlined/list_alt.svg";
import { ReactComponent as LegendToggle } from "@material-design-icons/svg/outlined/legend_toggle.svg";
import { ReactComponent as Extension } from "@material-design-icons/svg/outlined/extension.svg";
import { ReactComponent as NavigateNext } from "@material-design-icons/svg/outlined/navigate_next.svg";
import { ReactComponent as NavigateBefore } from "@material-design-icons/svg/outlined/navigate_before.svg";
import { ReactComponent as AssignmentTurnedIn } from "@material-design-icons/svg/outlined/assignment_turned_in.svg";
import { ReactComponent as WorkOff } from "@material-design-icons/svg/outlined/work_off.svg";
import { ReactComponent as AccountBalanceWallet } from "@material-design-icons/svg/outlined/account_balance_wallet.svg";
import { ReactComponent as PhotoCameraFront } from "@material-design-icons/svg/outlined/photo_camera_front.svg";
import { ReactComponent as Person } from "@material-design-icons/svg/outlined/person.svg";
import { ReactComponent as BusinessCenter } from "@material-design-icons/svg/outlined/business_center.svg";
import { ReactComponent as Phone } from "@material-design-icons/svg/outlined/phone.svg";
import { ReactComponent as Edit } from "@material-design-icons/svg/outlined/edit.svg";
import { ReactComponent as Link } from "@material-design-icons/svg/outlined/link.svg";
import { ReactComponent as DatasetLinked } from "@material-design-icons/svg/outlined/dataset_linked.svg";
import { ReactComponent as ArrowUpward } from "@material-design-icons/svg/outlined/arrow_upward.svg";
import { ReactComponent as ArrowDownward } from "@material-design-icons/svg/outlined/arrow_downward.svg";
import { ReactComponent as Sailing } from "@material-design-icons/svg/outlined/sailing.svg";
import { ReactComponent as DragIndicator } from "@material-design-icons/svg/outlined/drag_indicator.svg";

type NoIconProps = {
  name: string;
};

const NoIcon = ({ name }: NoIconProps) => {
  console.error(`Icon ${name} not found`);

  return <Box as="span" />;
};

type Props = {
  name: string;
  size?: string;
  fill?: boolean;
  weight?: string;
  p?: string;
  px?: string;
  py?: string;
  pl?: string;
  pr?: string;
  pt?: string;
  pb?: string;
  m?: string;
  mx?: string;
  my?: string;
  ml?: string;
  mr?: string;
  mt?: string;
  mb?: string;
  opacity?: string;
  color?: string;
};

interface IconMapping {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Icon = ({ name, size = "24px", p, px, py, pl, pr, pt, pb, m, mx, my, ml, mr, mt, mb, opacity, color }: Props) => {
  const iconMapping: IconMapping = {
    add: Add,
    arrow_back: ArrowBack,
    arrow_drop_down: ArrowDropDown,
    arrow_right_alt: ArrowRightAlt,
    autorenew: Autorenew,
    chat: Chat,
    chevron_left: ChevronLeft,
    chevron_right: ChevronRight,
    close: Close,
    cloud_upload: CloudUpload,
    compare_arrows: CompareArrows,
    contacts: Contacts,
    content_copy: ContentCopy,
    corporate_fare: CorporateFare,
    delete: Delete,
    description: Description,
    directions_car: DirectionsCar,
    do_not_disturb: DoNotDisturb,
    done: Done,
    email: Email,
    event: Event,
    expand_more: ExpandMore,
    expand_less: ExpandLess,
    garage: Garage,
    group: Group,
    groups: Groups,
    handshake: Handshake,
    info: Info,
    key: Key,
    logout: Logout,
    manage_accounts: ManageAccounts,
    manage_search: ManageSearch,
    map: Map,
    menu: Menu,
    more_vert: MoreVert,
    new_label: NewLabel,
    no_crash: NoCrash,
    payments: Payments,
    picture_as_pdf: PictureAsPdf,
    priority_high: PriorityHigh,
    published_with_changes: PublishedWithChanges,
    receipt_long: ReceiptLong,
    receipt: Receipt,
    remove: Remove,
    report: Report,
    schedule: Schedule,
    send: Send,
    settings: Settings,
    sticky_note_2: StickyNote2,
    subdirectory_arrow_left: SubdirectoryArrowLeft,
    flag: Flag,
    task_alt: TaskAlt,
    today: Today,
    visibility: Visibility,
    warning: Warning,
    warning_amber: WarningAmber,
    emoji_transportation: EmojiTransportation,
    list_alt: ListAlt,
    legend_toggle: LegendToggle,
    extension: Extension,
    navigate_next: NavigateNext,
    navigate_before: NavigateBefore,
    assignment_turned_in: AssignmentTurnedIn,
    work_off: WorkOff,
    account_balance_wallet: AccountBalanceWallet,
    photo_camera_front: PhotoCameraFront,
    person: Person,
    business_center: BusinessCenter,
    phone: Phone,
    edit: Edit,
    link: Link,
    dataset_linked: DatasetLinked,
    arrow_upward: ArrowUpward,
    arrow_downward: ArrowDownward,
    sailing: Sailing,
    drag_indicator: DragIndicator,
  };
  const IconComponent = iconMapping[name] || NoIcon;

  return (
    <Center
      p={p}
      px={px}
      py={py}
      pl={pl}
      pr={pr}
      pt={pt}
      pb={pb}
      m={m}
      mx={mx}
      my={my}
      ml={ml}
      mr={mr}
      mt={mt}
      mb={mb}
      opacity={opacity}
      fill={color ? color : "currentColor"}
      w={size}
      h={size}>
      <IconComponent width={size} height={size} name={name} />
    </Center>
  );
};

export default Icon;

import { useSearchParams } from "react-router-dom";
import { IEntityType } from "../domain-entity/types";
import { EntityMapping } from "../domain-entity";

const useGetRevision = (entity: IEntityType) => {
  const [searchParams] = useSearchParams();

  const revName = EntityMapping.get(entity)?.revision_query_param;
  const revisionId = revName ? searchParams.get(revName) : "";

  return revName && revisionId ? revisionId : undefined;
};

export default useGetRevision;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAuthParams } from "../auth/types";
import { API_BASE_URL } from "../config";
import { HF_tenant_key } from "../dealerAccounts/types";
import {
  IAccountDetails,
  IPatchAccountDetailsPayload,
  ISession,
  ISelectTenantForOwnAccountPayload,
  IAssignTenantsForInternalUserPayload,
  IListInternalUserTenants,
} from "./types";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["AccountDetails", "Session", "Tenants"],
  endpoints: (builder) => ({
    getSession: builder.query<ISession, IAuthParams>({
      query: ({ accessToken }) => {
        return {
          url: "/v1/session",
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Session"],
    }),
    getAccountDetails: builder.query<IAccountDetails, IAuthParams>({
      query: ({ accessToken }) => {
        return {
          url: "/v1/account",
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["AccountDetails"],
    }),
    changeAccountDetails: builder.mutation<
      {
        updated: boolean;
      },
      IPatchAccountDetailsPayload
    >({
      query: (payload) => {
        const { accessToken, ...body } = payload;
        return {
          url: "/v1/account",
          method: "PATCH",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["AccountDetails"],
    }),
    selectTenantForOwnAccount: builder.mutation<
      { updated: boolean },
      ISelectTenantForOwnAccountPayload
    >({
      query: ({ accessToken, tenant_key }) => {
        return {
          url: "/v1/account/selected_tenant",
          method: "PUT",
          body: { tenant_key },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Session"],
    }),
    assignTenantsForInternalUser: builder.mutation<
      { created: boolean },
      IAssignTenantsForInternalUserPayload
    >({
      query: ({ accessToken, user_id, tenant_keys }) => {
        return {
          url: `/v1/internal_user/${user_id}/visible_tenants`,
          method: "PUT",
          body: { tenant_keys },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Session", "Tenants"],
    }),
    listInternalUserTenants: builder.query<
      { tenant_keys: HF_tenant_key[] },
      IListInternalUserTenants
    >({
      query: ({ accessToken, user_id }) => {
        return {
          url: `/v1/internal_user/${user_id}/visible_tenants`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Tenants"],
    }),
  }),
});

export const {
  useGetSessionQuery,
  useGetAccountDetailsQuery,
  useChangeAccountDetailsMutation,
  useSelectTenantForOwnAccountMutation,
  useAssignTenantsForInternalUserMutation,
  useListInternalUserTenantsQuery,
} = accountApi;

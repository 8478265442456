import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IDeleteCreditApplicationPayload,
  IDeleteCreditApplicationResponse,
  IFetchApplicationsStatusBatchLookupPayload,
  IFetchApplicationsStatusBatchLookupResponse,
  IListCreditApplicationPayload,
  IListCreditApplicationResponse,
  IListCreditApplicationsPayload,
  IListCreditApplicationsResponse,
} from "./types";

export const loansApi = createApi({
  reducerPath: "loansApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Loans"],
  endpoints: (builder) => ({
    fetchCreditApplications: builder.query<IListCreditApplicationsResponse, IListCreditApplicationsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/consumer/credit_application",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Loans"],
    }),
    fetchCreditApplication: builder.query<IListCreditApplicationResponse, IListCreditApplicationPayload>({
      query: ({ accessToken, application_id, ...queryParams }) => {
        return {
          url: `/v1/consumer/credit_application/${application_id}`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Loans"],
    }),
    fetchApplicationsStatusBatchLookup: builder.mutation<
      IFetchApplicationsStatusBatchLookupResponse,
      IFetchApplicationsStatusBatchLookupPayload
    >({
      query: ({ accessToken, ...params }) => {
        return {
          url: "/v1/consumer/credit_application_batch_lookup",
          method: "POST",
          body: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteLoanApplication: builder.mutation<IDeleteCreditApplicationResponse, IDeleteCreditApplicationPayload>({
      query: ({ accessToken, application_id }) => {
        return {
          url: `/v1/consumer/credit_application/${application_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Loans"],
    }),
  }),
});

export const {
  useFetchCreditApplicationsQuery,
  useFetchCreditApplicationQuery,
  useFetchApplicationsStatusBatchLookupMutation,
  useDeleteLoanApplicationMutation,
} = loansApi;

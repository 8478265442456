import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetContactsPayload,
  IGetContactsResponse,
  IGetContactPayload,
  IGetContactResponse,
  IPostContactPayload,
  IPostContactResponse,
  IDeleteContactPayload,
  IEditContactPayload,
  IEditContactResponse,
  IRelation,
  IGetRelationsPayload,
  IPostRelationPayload,
  IDeleteRelationPayload,
} from "./types";

export const contactsApi = createApi({
  reducerPath: "contactsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Contacts", "Relations"],
  endpoints: (builder) => ({
    getContacts: builder.query<IGetContactsResponse, IGetContactsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/legal_entity",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Contacts"],
    }),
    getContact: builder.query<IGetContactResponse, IGetContactPayload>({
      query: ({ accessToken, contact_id, ...queryParams }) => {
        return {
          url: `/v1/legal_entity/${contact_id}`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Contacts"],
    }),
    postContact: builder.mutation<IPostContactResponse, IPostContactPayload>({
      query: ({ accessToken, contact }) => {
        return {
          url: "/v1/legal_entity",
          method: "POST",
          body: { legal_entity: contact },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Contacts"],
    }),
    deleteContact: builder.mutation<{ updated: boolean }, IDeleteContactPayload>({
      query: ({ accessToken, contact_id }) => {
        return {
          url: `/v1/legal_entity/${contact_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Contacts"],
    }),
    editContact: builder.mutation<IEditContactResponse, IEditContactPayload>({
      query: ({ accessToken, contact, contact_id, revision_id }) => {
        return {
          url: `/v1/legal_entity/${contact_id}`,
          method: "PUT",
          body: { legal_entity: contact, revision_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Contacts"],
    }),
    getRelations: builder.query<{ relations: IRelation[] }, IGetRelationsPayload>({
      query: ({ accessToken, contact_id }) => {
        return {
          url: `/v1/legal_entity/${contact_id}/relation`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Relations"],
    }),
    postRelation: builder.mutation<{ updated: boolean }, IPostRelationPayload>({
      query: ({ accessToken, contact_id, to_id, relation_type }) => {
        return {
          url: `/v1/legal_entity/${contact_id}/add_relation`,
          method: "POST",
          body: { to_id, relation_type },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Relations"],
    }),
    deleteRelation: builder.mutation<{ updated: boolean }, IDeleteRelationPayload>({
      query: ({ accessToken, contact_id, to_id, relation_type }) => {
        return {
          url: `/v1/legal_entity/${contact_id}/remove_relation`,
          method: "DELETE",
          body: { to_id, relation_type },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Relations"],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useGetContactQuery,
  usePostContactMutation,
  useDeleteContactMutation,
  useEditContactMutation,
  useGetRelationsQuery,
  usePostRelationMutation,
  useDeleteRelationMutation,
} = contactsApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IDomainEventsResponse,
  IDomainEventsFetchPayload,
  IGetDomainEventPayload,
  IGetDomainEventResponse,
} from "./types";

export const domainEventsApi = createApi({
  reducerPath: "domainEventsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getEvents: builder.query<IDomainEventsResponse, IDomainEventsFetchPayload>({
      query: ({ accessToken, ...params }) => {
        return {
          url: "/v1/event",
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getEvent: builder.query<IGetDomainEventResponse, IGetDomainEventPayload>({
      query: ({ accessToken, event_id }) => {
        return {
          url: `/v1/event/${event_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetEventsQuery, useGetEventQuery } = domainEventsApi;

import textarea from "./textarea";
import form from "./form";
import select from "./select";
import input from "./input";
import menu from "./menu";
import modal from "./modal";
import accordion from "./accordion";
import tag from "./tag";
import text from "./text";
import checkbox from "./checkbox";
import radio from "./radio";
import divider from "./divider";
import button from "./button";
import table from "./table";
import badge from "./badge";
import tabs from "./tabs";
import switchToggle from "./switch";
import link from "./link";
import tooltip from "./tooltip";
import drawer from "./drawer";

const themeComponents = {
  textarea: { ...textarea },
  form: { ...form },
  select: { ...select },
  input: { ...input },
  menu: { ...menu },
  modal: { ...modal },
  accordion: { ...accordion },
  tag: { ...tag },
  text: { ...text },
  checkbox: { ...checkbox },
  radio: { ...radio },
  divider: { ...divider },
  button: { ...button },
  table: { ...table },
  badge: { ...badge },
  tabs: { ...tabs },
  switchToggle: { ...switchToggle },
  link: { ...link },
  tooltip: { ...tooltip },
  drawer: { ...drawer },
};

export default themeComponents;

const themeLayerStyles = {
  sidebarNav: {
    width: 60,
    color: "white",
    height: "100%",
    position: "sticky",
    zIndex: "sticky",
    top: "0",
    fontSize: "sm",
    lineHeight: "sm",
  },

  sidebarNavSmall: {
    width: "90px",
    color: "white",
    height: "100%",
    position: "sticky",
    zIndex: "sticky",
    top: "0",
    fontSize: "sm",
    lineHeight: "sm",
  },

  sidebarLogo: {
    px: 3,
    py: 6,
    borderBottom: "1px",
    borderBottomColor: "purple.900",
  },
  sidebarMenuWrapper: {
    w: "100%",
    overflow: "auto",
    p: "3",
  },
  topBarWrapper: {
    position: "sticky",
    zIndex: "sticky",
    top: 0,
    h: 14,
    minH: 14,
    pr: { base: 4, lg: 8 },
    pl: { base: 2, lg: 8 },
    alignItems: "center",
    bg: "purple.900",
    color: "#fff",
  },
  contentTopBar: {
    w: "100%",
    bg: "grayOffWhite.500",
    py: 2,
    px: { base: 4, lg: 8 },
    gap: 2,
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    boxShadow: "base",
  },
  actionStack: {
    flexWrap: "wrap",
    gap: 2,
    bg: "white",
    p: 4,
    mb: 4,
  },
  dealFlowGrid: {
    gridTemplateColumns: { base: "1fr", lg: "1fr 320px" },
    gap: 4,
    alignItems: "start",
  },
  stickySidebar: {
    position: { base: "relative", lg: "sticky" },
    zIndex: { base: "auto", lg: "sticky" },
    top: { base: "auto", lg: "166px" },
  },
  card: {
    bg: "white",
    padding: { base: 4, md: 8 },
  },
  code: {
    padding: "2",
    bg: "#222",
    borderRadius: "4px",
    color: "#fff",
    fontSize: "14px",
    lineHeight: "24px",
    margin: "8px 0",
    fontFamily: "mono",
  },
  mainContent: {
    flexDirection: "column",
    w: "full",
    px: { base: 4, lg: 8 },
    py: 6,
  },
  uploadField: {
    border: "2px dashed",
    borderColor: "grayLight.500",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer",
    transition: "all 120ms ease-in-out",
    _hover: {
      borderColor: "grayRegular.500",
    },
  },
  uploadLabel: {
    margin: "0",
    color: "grayMid.500",
    cursor: "pointer",
    w: "full",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    p: "4",
  },
  inputResultsList: {
    position: "absolute",
    top: "100%",
    left: "0",
    right: "0",
    background: "white",
    color: "grayDark.500",
    mt: "2px",
    boxShadow: "base",
    border: "1px",
    borderColor: "purple.500",
    maxHeight: "50vh", //TODO: Sofro
    overflow: "auto",
    zIndex: "1101",
  },
  inputResultsListItem: {
    px: 3,
    py: 2,
    cursor: "pointer",
    transition: "all 120ms ease-in-out",
    position: "relative",
    borderBottom: "1px",
    borderColor: "grayLight.500",
    _hover: {
      bg: "grayLight.500",
    },
  },
  autosizeTextarea: {
    width: "100%",
    minWidth: "0px",
    minHeight: "40px",
    appearance: "none",
    fontSize: "md",
    outline: "2px",
    outlineColor: "transparent",
    outlineOffset: "2px",
    transitionProperty: "common",
    transitionDuration: "normal",
    padding: "7px 12px",
    border: "1px",
    borderColor: "grayLight.500",
    bg: "#fff",
    _hover: {
      borderColor: "purple.500",
    },
    _focus: {
      borderColor: "purple.600",
      outline: "none",
    },
    _focusVisible: {
      borderColor: "purple.600",
      outline: "none",
      boxShadow: "none",
    },
    _disabled: {
      color: "grayMid.500",
      opacity: 1,
      bg: "disabled.500",
      borderColor: "grayLight.500",
      _hover: {
        color: "grayMid.500",
        opacity: 1,
        bg: "disabled.500",
        borderColor: "grayLight.500",
      },
    },
  },
  iconLink: {
    position: "relative",
    w: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    whiteSpace: "nowrap",
    px: "2",
    py: "2",
    fontSize: "md",
    lineHeight: "md",
    color: "purple.600",
    bg: "#fff",
    transition: "all 120ms ease-in-out",
    cursor: "pointer",
    borderBottom: "2px solid",
    borderBottomColor: "transparent",
    _hover: {
      bg: "purple.100",
    },
    _active: {
      bg: "purple.200",
    },
    _disabled: {
      color: "gray.500",
      bg: "gray.400",
      _hover: {
        color: "gray.500",
        bg: "gray.400",
      },
    },
    _selected: {
      color: "purple.800",
      bg: "purple.200",
      borderBottomColor: "purple.800",
    },
  },
  spacedBox: {
    px: "4",
    py: "2",
  },
};

export default themeLayerStyles;

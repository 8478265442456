import { v4 as uuidv4 } from "uuid";
import {
  IConstructedNotifications,
  IDealerNotification,
  IFetchNotificationTreeResponse,
  IListenersAccumulator,
  INotificationListener,
  INotificationTypesSchema,
} from "../types";

// export const notifications: IDealerNotification[] = [
//   {
//     channel: DealerNotificationChannel.EMAIL,
//     labels: ["all", "invoicing_all", "invoicing_financing"],
//     email: "x@p.com",
//   },
//   { channel: DealerNotificationChannel.EMAIL, labels: ["invoicing_all"], email: "x1@p.com" },
//   { channel: DealerNotificationChannel.SMS, labels: ["invoicing_all", "invoicing_advance"], phone_no: "25423423432" },
//   { channel: DealerNotificationChannel.SMS, labels: ["payments_leasing_notice"], phone_no: "23423423432" },
// ];

// export const NotificationTypesSchema = {
//   label: "all",
//   listeners: [],
//   sub_notification_types: [
//     {
//       label: "invoicing_all",
//       listeners: [],
//       sub_notification_types: [
//         {
//           label: "invoicing_advance",
//           sub_notification_types: [],
//           listeners: [],
//         },
//         {
//           label: "invoicing_financing",
//           sub_notification_types: [],
//           listeners: [],
//         },
//         {
//           label: "invoicing_credit",
//           sub_notification_types: [],
//           listeners: [],
//         },
//       ],
//     },
//     {
//       label: "payments_all",
//       listeners: [],
//       sub_notification_types: [
//         {
//           label: "payments_leasing_notice",
//           sub_notification_types: [],
//           listeners: [],
//         },
//       ],
//     },
//   ],
// };

function makeNotificationsStructure(listeners: IListenersAccumulator[]) {
  const constructedNotifications: IConstructedNotifications = {};
  for (let listener of listeners) {
    const { email, phone_no, channel, label } = listener;
    if (email) {
      if (constructedNotifications[email]) {
        constructedNotifications[email].labels.push(label);
      } else {
        constructedNotifications[email] = { email: email, channel: channel, labels: [label] };
      }
    } else if (phone_no) {
      if (constructedNotifications[phone_no]) {
        constructedNotifications[phone_no].labels.push(label);
      } else {
        constructedNotifications[phone_no] = { phone_no: phone_no, channel: channel, labels: [label] };
      }
    }
  }
  return Object.values(constructedNotifications);
}

function extractListeners(tree: INotificationTypesSchema, listenersAccumulator: IListenersAccumulator[]) {
  if (tree.listeners) {
    const constructedListeners = tree.listeners.map((listener) => ({ ...listener, label: tree.label }));
    listenersAccumulator.push(...constructedListeners);
  }

  for (let subtree of tree.sub_notification_types) {
    extractListeners(subtree, listenersAccumulator);
  }

  return listenersAccumulator;
}

export const insertListener = (tree: INotificationTypesSchema, listener: INotificationListener, label: string) => {
  if (!tree.label) return tree;

  if (tree.label === label) {
    const { email, phone_no, channel } = listener;
    tree.listeners.push({ channel, email, phone_no, guid: uuidv4() });
    return tree;
  }

  for (let subtree of tree.sub_notification_types) {
    insertListener(subtree, listener, label);
  }
  return tree;
};

function populateLayoutListeners(tree: INotificationTypesSchema, notifications: IDealerNotification[]) {
  for (let notification of notifications) {
    for (let label of notification.labels) {
      if (label === "all") {
        const { email, phone_no, channel } = notification;
        tree.listeners.push({ email, phone_no, channel, guid: uuidv4() });
      } else {
        insertListener(tree, notification, label);
      }
    }
  }
  return tree;
}

export function updateListener(tree: INotificationTypesSchema, listener: INotificationListener) {
  for (let i = 0; i < tree.listeners.length; i++) {
    if (tree.listeners[i].guid === listener.guid) {
      tree.listeners[i] = listener;
      break;
    }
  }

  for (let subtree of tree.sub_notification_types) {
    updateListener(subtree, listener);
  }

  return tree;
}

export function deleteListener(tree: INotificationTypesSchema, listener: INotificationListener) {
  for (let i = 0; i < tree.listeners.length; i++) {
    if (tree.listeners[i].guid === listener.guid) {
      tree.listeners.splice(i, 1);
      break;
    }
  }

  for (let subtree of tree.sub_notification_types) {
    deleteListener(subtree, listener);
  }

  return tree;
}

export function getPopulatedLayoutWithListeners(
  notifications: IDealerNotification[],
  notificationsTree: INotificationTypesSchema
) {
  const schema = JSON.parse(JSON.stringify(notificationsTree));
  const tree = populateLayoutListeners(schema, notifications);
  return tree;
}

export function getNotificationsFromLayout(tree: INotificationTypesSchema) {
  const listeners = extractListeners(tree, []);
  const notifications = makeNotificationsStructure(listeners);
  return notifications;
}

export function createNotificationTree(notificationTreeJson: IFetchNotificationTreeResponse) {
  if (!notificationTreeJson || !Object.keys(notificationTreeJson).length) return;
  const createSubNotificationTree = (subNotificationTypes: string[]) => {
    return subNotificationTypes.map((notificationType: string) => {
      return {
        label: notificationType,
        listeners: [],
        sub_notification_types: [],
      };
    });
  };

  function createSchema(notificationTreeJson: object, treeSchema: object) {
    if (!notificationTreeJson || !Object.keys(notificationTreeJson).length) return;
    for (const [key, value] of Object.entries(notificationTreeJson)) {
      const localSchema: INotificationTypesSchema = {
        label: key,
        listeners: [],
        sub_notification_types: [],
      };

      if (!Array.isArray(value)) {
        createSchema(value, localSchema.sub_notification_types);
      } else {
        const subNotificationTypes = createSubNotificationTree(value);
        localSchema.sub_notification_types = subNotificationTypes;
      }

      if (Array.isArray(treeSchema)) {
        treeSchema.push(localSchema);
      } else {
        treeSchema = localSchema;
      }
      //if notificationTreeJson will be with multiple keys on first level, then we need only to push localSchema to array
      // treeSchema.push(localSchema);
    }
    return treeSchema;
  }
  const schema = createSchema(notificationTreeJson, {});

  return schema as INotificationTypesSchema;
}

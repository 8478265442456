import React from "react";
import { useState } from "react";
import { Input, InputProps } from "@chakra-ui/react";
import { useRef } from "react";
import { useEffect } from "react";

interface Props extends Omit<InputProps, "onChange" | "value"> {
  onChange: (value: string) => void;
  value: string;
  hasRightElement?: boolean;
  hasLeftElement?: boolean;
}

const DebouncedInput = (props: Props) => {
  const timer = useRef<NodeJS.Timeout | undefined>();
  const { value, onChange, hasRightElement, hasLeftElement, ...rest } = props;

  const [internalValue, setValue] = useState<string>(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setValue(value);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => onChange(value), 400);
  };

  return (
    <Input
      value={internalValue || ""}
      onChange={handleChange}
      pr={hasRightElement ? "10" : "3"}
      pl={hasLeftElement ? "10" : "3"}
      {...rest}
    />
  );
};

export default DebouncedInput;

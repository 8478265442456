import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetDealsResponse,
  IGetDealsPayload,
  IGetDealResponse,
  IGetDealPayload,
  IPostDealResponse,
  IPostDealPayload,
  IPatchDealResponse,
  IPatchDealPayload,
  IGetDealCountPayload,
  IGetDealAssessmentsPayload,
  IGetDealAssessmentsResponse,
  ICreateDealAssessmentPayload,
  ICreateDealAssessmentResponse,
  IDeleteDealAssessmentPayload,
  IGetContractTemplatesPayload,
  IGetContractTemplatesResponse,
  ICreateContractPreviewPayload,
  ICreateContractPayload,
  IGetContractsPayload,
  IGetContractsResponse,
  IContract,
  IGetContractPayload,
  ICancelSigningProcessPayload,
  IInitiateContractSigningPayload,
  ICancelDealPayload,
  IActivateDealPayload,
  IGetPayoutTasksPayload,
  IGetPayoutTaskPayload,
  IPayoutTask,
  IGetDealerCreditPayload,
  IDealerCredit,
  IGetInvoicesPayloads,
  IGetInvoicesResponse,
  IGetPayoutsPayload,
  IGetPayoutsResponse,
  IDealOfferPayload,
  IDealOffer,
  IImportInvoicesPayload,
  IGetDealInvoicesPayload,
  IGetDealInvoicesResponse,
  IAddDealInvoicePayload,
  ISendInvoiceToAros,
  IPatchPayoutTaskPayload,
  IViewInvoicePayload,
  IGenerateDepositUrlPayload,
  IChangeToServiceStateResponse,
  IChangeToServiceStatePayload,
  IGeneratePayoutTaskWithdrawalLinkPayload,
  IGetB2CWithdrawalUrlResponse,
  IGetB2CWithdrawalUrlPayload,
  IGetB2BWithdrawalUrlPayload,
  IAproveWithdrawalPayload,
  IPublishOfferPayload,
  IGetSupplierDocumentsPayload,
  IGetSupplierDocumentsResponse,
  ICreateSupplierDocumentPayload,
  ICreateSupplierDocumentsResponse,
  IImportSupplierDocumentPayload,
  IAttachInvoiceFilePayload,
  IAttachInvoiceFileResponse,
  IAttachSupplierInvoiceFilePayload,
  IAttachSupplierInvoiceFileResponse,
  IGetSupplierDocumentResponse,
  ILookupSupplierDocumentPayload,
  ILookupSupplierDocumentResponse,
  ILookupInvoiceDocumentPayload,
  ILookupInvoiceDocumentResponse,
  IGetPurchasePriceBreakdownPayload,
  IGetPurchasePriceBreakdownResponse,
  IInvalidateOfferingPayload,
  IInvalidateOfferingResponse,
  IGetOwnershipHandlerPayload,
  IGetOwnershipHandlerResponse,
  IPostOwnershipTradeInPayload,
  IPostOwnershipTradeInResponse,
  IPostFragusOwnershipTradeInResponse,
  IPostFragusOwnershipTradeInPayload,
  IAssignFragusOwnershipLeasingToDealerResponse,
  IAssignFragusOwnershipLeasingToDealerPayload,
  ISetOwnershipTradeInCompleteResponse,
  ISetOwnershipTradeInCompletePayload,
  ISetOwnershipSaleCompleteResponse,
  ISetOwnershipSaleCompletePayload,
  IGetFragusOwnershipStatusResponse,
  IGetFragusOwnershipStatusPayload,
  ICreateFragusSaleOwnershipResponse,
  ICreateFragusSaleOwnershipPayload,
  IFetchContractSignersPayload,
  IFetchContractSignersResponse,
  IExportDealsPayload,
  IExportDealsResponse,
  IGetVatRatesPayload,
  IGetVatRatesResponse,
  ICheckDealValidityPayload,
  ICheckDealValidityResponse,
  IAcceptOfferPayload,
  IAcceptOfferResponse,
  IFetchDefaultOfferResponse,
  IFetchDefaultOfferPayload,
  IGetDefaultOfferPayload,
  IGetDefaultOfferPayloadResponse,
  IGenerateTransactionReportPayload,
  IGenerateTransactionReportResponse,
} from "./types";

export const dealsApi = createApi({
  reducerPath: "dealsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Deal", "Invoices", "Payout_task", "SupplierInvoices"],
  endpoints: (builder) => ({
    getDeals: builder.query<IGetDealsResponse, IGetDealsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/deal",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getDeal: builder.query<IGetDealResponse, IGetDealPayload>({
      query: ({ accessToken, deal_id, ...params }) => {
        return {
          url: `/v1/deal/${deal_id}`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    createDeal: builder.mutation<IPostDealResponse, IPostDealPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/deal",
          method: "POST",
          body: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    patchDeal: builder.mutation<IPatchDealResponse, IPatchDealPayload>({
      query: ({ accessToken, deal_id, deal, revision_id }) => {
        return {
          url: `/v1/deal/${deal_id}`,
          method: "PATCH",
          body: { ...deal, revision_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getDealCount: builder.query<{ count: number }, IGetDealCountPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/deal_count",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getDealAssessments: builder.query<IGetDealAssessmentsResponse, IGetDealAssessmentsPayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/assessment`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    createDealAssessment: builder.mutation<ICreateDealAssessmentResponse, ICreateDealAssessmentPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/assessment`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    deleteDealAssessment: builder.mutation<{ updated: boolean }, IDeleteDealAssessmentPayload>({
      query: ({ accessToken, deal_id, assessment_id }) => {
        return {
          url: `/v1/deal/${deal_id}/assessment/${assessment_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getContractTemplates: builder.query<IGetContractTemplatesResponse, IGetContractTemplatesPayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/contract_template`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    createContractPreview: builder.mutation<{ html: string }, ICreateContractPreviewPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/preview_contract`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    createContract: builder.mutation<{ process_id: string }, ICreateContractPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/contract`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getContracts: builder.query<IGetContractsResponse, IGetContractsPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/contract`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getContract: builder.query<IContract, IGetContractPayload>({
      query: ({ accessToken, process_id }) => {
        return {
          url: `/v1/contract/${process_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    cancelSigningProcess: builder.mutation<{ updated: boolean }, ICancelSigningProcessPayload>({
      query: ({ accessToken, process_id }) => {
        return {
          url: `/v1/contract/${process_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    initiateContractSigning: builder.mutation<{ updated: boolean }, IInitiateContractSigningPayload>({
      query: ({ accessToken, process_id }) => {
        return {
          url: `/v1/contract/${process_id}/start`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    cancelDeal: builder.mutation<{ revision_id: string; updated: boolean }, ICancelDealPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/cancel`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    activateDeal: builder.mutation<{ revision_id: string; updated: boolean }, IActivateDealPayload>({
      query: ({ accessToken, deal_id, revision_id }) => {
        return {
          url: `/v1/deal/${deal_id}/activate`,
          method: "POST",
          body: { revision_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getPayoutTasks: builder.query<{ tasks: IPayoutTask[] }, IGetPayoutTasksPayload>({
      query: ({ accessToken, tag, ...queryParams }) => {
        return {
          url: `/v1/payout_task?tag=${tag}`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Payout_task"],
    }),
    getPayoutTask: builder.query<IPayoutTask, IGetPayoutTaskPayload>({
      query: ({ accessToken, task_id }) => {
        return {
          url: `/v1/payout_task/${task_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Payout_task"],
    }),
    patchPayoutTask: builder.mutation<{}, IPatchPayoutTaskPayload>({
      query: ({ accessToken, task_id, ...payload }) => {
        return {
          url: `/v1/payout_task/${task_id}`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Payout_task"],
    }),
    generatePayoutTaskWithdrawalLink: builder.mutation<{ url: string }, IGeneratePayoutTaskWithdrawalLinkPayload>({
      query: ({ accessToken, task_id, ...payload }) => {
        return {
          url: `/v1/payout_task/${task_id}/withdrawal_link`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Payout_task"],
    }),
    getB2CWithdrawalUrl: builder.mutation<IGetB2CWithdrawalUrlResponse, IGetB2CWithdrawalUrlPayload>({
      query: ({ task_id, ...payload }) => {
        return {
          url: `/v1/payout_task/${task_id}/b2c-withdrawal`,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["Payout_task"],
    }),
    B2BWithdrawal: builder.mutation<{}, IGetB2BWithdrawalUrlPayload>({
      query: ({ accessToken, task_id }) => {
        return {
          url: `/v1/payout_task/${task_id}/b2b-withdrawal`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Payout_task"],
    }),
    approvePayoutWithdrawal: builder.mutation<{}, IAproveWithdrawalPayload>({
      query: ({ accessToken, task_id }) => {
        return {
          url: `/v1/payout_task/${task_id}/withdrawal/approve`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Payout_task"],
    }),
    getDealerCredit: builder.query<IDealerCredit, IGetDealerCreditPayload>({
      query: ({ accessToken, credit_id }) => {
        return {
          url: `/v1/dealer_credit/${credit_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getInvoices: builder.query<IGetInvoicesResponse, IGetInvoicesPayloads>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/invoice`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getPayouts: builder.query<IGetPayoutsResponse, IGetPayoutsPayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/preview_payout_table`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    generateOfferDetails: builder.mutation<IDealOffer, IDealOfferPayload>({
      query: ({ accessToken, deal_id, ...bodyParams }) => {
        return {
          url: `/v1/deal/${deal_id}/offer`,
          method: "POST",
          body: bodyParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    fetchDefaultOffer: builder.mutation<IFetchDefaultOfferResponse, IFetchDefaultOfferPayload>({
      query: ({ accessToken, deal_id, ...bodyParams }) => {
        return {
          url: `/v1/deal/${deal_id}/default_offer`,
          method: "POST",
          body: bodyParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getDefaultOffer: builder.query<IGetDefaultOfferPayloadResponse, IGetDefaultOfferPayload>({
      query: ({ accessToken, deal_id, ...params }) => {
        return {
          url: `/v1/deal/${deal_id}/offer`,
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    getDealInvoices: builder.query<IGetDealInvoicesResponse, IGetDealInvoicesPayload>({
      query: ({ accessToken, ...params }) => {
        return {
          url: "/v1/invoices",
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Invoices"],
    }),
    importInvoices: builder.mutation<{}, IImportInvoicesPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/import_invoice`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Invoices"],
    }),
    addInvoice: builder.mutation<{}, IAddDealInvoicePayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/invoice`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Invoices"],
    }),
    sendInvoiceToAros: builder.mutation<{}, ISendInvoiceToAros>({
      query: ({ accessToken, deal_id, invoice_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/invoice/${invoice_id}/export`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Invoices"],
    }),
    viewInvoice: builder.mutation<{ url: string }, IViewInvoicePayload>({
      query: ({ accessToken, invoice_id }) => {
        return {
          url: `/v1/invoices/${invoice_id}/view`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    generateDepositLink: builder.mutation<{ url: string }, IGenerateDepositUrlPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/deposit_url`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    changeToServiceState: builder.mutation<IChangeToServiceStateResponse, IChangeToServiceStatePayload>({
      query: ({ accessToken, deal_id, revision_id }) => {
        return {
          url: `/v1/deal/${deal_id}/service`,
          method: "POST",
          body: { revision_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    publishOffer: builder.mutation<{}, IPublishOfferPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/publish_offer`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getSupplierDocuments: builder.query<IGetSupplierDocumentsResponse, IGetSupplierDocumentsPayload>({
      query: ({ accessToken, ...params }) => {
        return {
          url: "/v1/supplier_documents",
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SupplierInvoices"],
    }),
    lookupSupplierDocument: builder.query<ILookupSupplierDocumentResponse, ILookupSupplierDocumentPayload>({
      query: ({ accessToken, document_id }) => {
        return {
          url: `/v1/supplier_documents/${document_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SupplierInvoices"],
    }),
    createDealSupplierDocument: builder.mutation<ICreateSupplierDocumentsResponse, ICreateSupplierDocumentPayload>({
      query: ({ accessToken, deal_id, ...params }) => {
        return {
          url: `/v1/deal/${deal_id}/supplier_document`,
          method: "POST",
          body: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SupplierInvoices"],
    }),
    importDealSupplierDocument: builder.mutation<IGetSupplierDocumentResponse, IImportSupplierDocumentPayload>({
      query: ({ accessToken, deal_id, ...params }) => {
        return {
          url: `/v1/deal/${deal_id}/import_supplier_document`,
          method: "POST",
          body: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SupplierInvoices"],
    }),
    attachFileToSupplierInvoice: builder.mutation<
      IAttachSupplierInvoiceFileResponse,
      IAttachSupplierInvoiceFilePayload
    >({
      query: ({ accessToken, document_id, file_id }) => {
        return {
          url: `/v1/supplier_documents/${document_id}/attach_file/${file_id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SupplierInvoices"],
    }),
    attachFileToInvoice: builder.mutation<IAttachInvoiceFileResponse, IAttachInvoiceFilePayload>({
      query: ({ accessToken, invoice_id, file_id }) => {
        return {
          url: `/v1/invoices/${invoice_id}/attach_file/${file_id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Invoices"],
    }),
    lookupInvoiceDocument: builder.query<ILookupInvoiceDocumentResponse, ILookupInvoiceDocumentPayload>({
      query: ({ accessToken, invoice_id }) => {
        return {
          url: `/v1/invoices/${invoice_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Invoices"],
    }),
    getPurchasePriceBreakdown: builder.query<IGetPurchasePriceBreakdownResponse, IGetPurchasePriceBreakdownPayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/purchase_price_breakdown`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    invalidateDealOffer: builder.mutation<IInvalidateOfferingResponse, IInvalidateOfferingPayload>({
      query: ({ accessToken, deal_id, revision_id }) => {
        return {
          url: `/v1/deal/${deal_id}/invalidate_offer`,
          method: "POST",
          body: { revision_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getOwnershipHandler: builder.query<IGetOwnershipHandlerResponse, IGetOwnershipHandlerPayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/ownership_handler`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    ownershipTradeIn: builder.mutation<IPostOwnershipTradeInResponse, IPostOwnershipTradeInPayload>({
      query: ({ accessToken, deal_id, ...payload }) => {
        return {
          url: `/v1/deal/${deal_id}/ownership_trade_in`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    getFragusOwnershipStatus: builder.query<IGetFragusOwnershipStatusResponse, IGetFragusOwnershipStatusPayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: "/v1/integration/fragus/ownership_status",
          method: "GET",
          params: { deal_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    fragusOwnershipTradeIn: builder.mutation<IPostFragusOwnershipTradeInResponse, IPostFragusOwnershipTradeInPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/integration/fragus/ownership_trade_in`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    assignFragusOwnershipLeasingToDealer: builder.mutation<
      IAssignFragusOwnershipLeasingToDealerResponse,
      IAssignFragusOwnershipLeasingToDealerPayload
    >({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/integration/fragus/ownership_leasing`,
          method: "PUT",
          body: { deal_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    setOwnershipTradeInComplete: builder.mutation<
      ISetOwnershipTradeInCompleteResponse,
      ISetOwnershipTradeInCompletePayload
    >({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/set_ownership_trade_in_complete`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    setOwnershipSaleComplete: builder.mutation<ISetOwnershipSaleCompleteResponse, ISetOwnershipSaleCompletePayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/set_ownership_sale_complete`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    createFragusSaleOwnershipTransaction: builder.mutation<
      ICreateFragusSaleOwnershipResponse,
      ICreateFragusSaleOwnershipPayload
    >({
      query: ({ accessToken, deal_id, is_dry_run }) => {
        return {
          url: `/v1/integration/fragus/ownership_sale`,
          method: "POST",
          body: { deal_id, is_dry_run },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    fetchContractSigners: builder.query<IFetchContractSignersResponse, IFetchContractSignersPayload>({
      query: ({ accessToken, dealId, ...params }) => {
        return {
          url: `/v1/deal/${dealId}/contract_signers`,
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    exportDeals: builder.mutation<IExportDealsResponse, IExportDealsPayload>({
      query: ({ accessToken, file_name, ...params }) => {
        return {
          url: `/v1/deal_export`,
          method: "POST",
          params,
          body: { file_name },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getVatRates: builder.query<IGetVatRatesResponse, IGetVatRatesPayload>({
      query: ({ accessToken, hf_tenant_key }) => {
        return {
          url: `/v1/vat_table`,
          method: "GET",
          params: { hf_tenant_key },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    checkDealValidity: builder.query<ICheckDealValidityResponse, ICheckDealValidityPayload>({
      query: ({ accessToken, dealId }) => {
        return {
          url: `/v1/deal/${dealId}/validate`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deal"],
    }),
    acceptOffer: builder.mutation<IAcceptOfferResponse, IAcceptOfferPayload>({
      query: ({ accessToken, deal_id, revision_id }) => {
        return {
          url: `/v1/deal/${deal_id}/accept_offer`,
          method: "POST",
          body: { revision_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deal"],
    }),
    generateTransactionReport: builder.mutation<IGenerateTransactionReportResponse, IGenerateTransactionReportPayload>({
      query: ({ accessToken, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/transaction_report`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      // invalidatesTags: ["Deal"],
    }),
  }),
});

export const {
  useGetDealQuery,
  useGetDealsQuery,
  useCreateDealMutation,
  usePatchDealMutation,
  useGetDealCountQuery,
  useGetDealAssessmentsQuery,
  useCreateDealAssessmentMutation,
  useDeleteDealAssessmentMutation,
  useGetContractTemplatesQuery,
  useCreateContractPreviewMutation,
  useCreateContractMutation,
  useGetContractsQuery,
  useGetContractQuery,
  useCancelSigningProcessMutation,
  useInitiateContractSigningMutation,
  useCancelDealMutation,
  useActivateDealMutation,
  useGetPayoutTasksQuery,
  useGetPayoutTaskQuery,
  usePatchPayoutTaskMutation,
  useGetDealerCreditQuery,
  useGetInvoicesQuery,
  useGetPayoutsQuery,
  useGeneratePayoutTaskWithdrawalLinkMutation,
  useGetB2CWithdrawalUrlMutation,
  useB2BWithdrawalMutation,
  useApprovePayoutWithdrawalMutation,
  useGenerateOfferDetailsMutation,
  useImportInvoicesMutation,
  useGetDealInvoicesQuery,
  useAddInvoiceMutation,
  useSendInvoiceToArosMutation,
  useViewInvoiceMutation,
  useGenerateDepositLinkMutation,
  useChangeToServiceStateMutation,
  usePublishOfferMutation,
  useGetSupplierDocumentsQuery,
  useCreateDealSupplierDocumentMutation,
  useImportDealSupplierDocumentMutation,
  useAttachFileToSupplierInvoiceMutation,
  useAttachFileToInvoiceMutation,
  useLookupSupplierDocumentQuery,
  useLookupInvoiceDocumentQuery,
  useGetPurchasePriceBreakdownQuery,
  useInvalidateDealOfferMutation,
  useGetOwnershipHandlerQuery,
  useOwnershipTradeInMutation,
  useGetFragusOwnershipStatusQuery,
  useFragusOwnershipTradeInMutation,
  useAssignFragusOwnershipLeasingToDealerMutation,
  useSetOwnershipTradeInCompleteMutation,
  useSetOwnershipSaleCompleteMutation,
  useCreateFragusSaleOwnershipTransactionMutation,
  useFetchContractSignersQuery,
  useExportDealsMutation,
  useGetVatRatesQuery,
  useCheckDealValidityQuery,
  useAcceptOfferMutation,
  useFetchDefaultOfferMutation,
  useGetDefaultOfferQuery,
  useGenerateTransactionReportMutation,
} = dealsApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetCreditsPayload,
  IGetCreditsResponse,
  IGetCreditDetailsResponse,
  IGetCreditDetailsPayload,
  IGetCreditLogResponse,
  IGetCreditLogPayload,
  IGetCreditSimulationPayload,
  IGetCreditSimulationResponse,
  IFetchCreditParamsResponse,
  IFetchCreditParamsPayload,
  IExportCreditsResponse,
  IExportCreditsPayload,
  IDepreciateCreditResponse,
  IDepreciateCreditPayload,
  IDefaultDealerCreditPayload,
  IDefaultDealerCreditResponse,
} from "./types";

export const creditsApi = createApi({
  reducerPath: "creditsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Credits", "Credit", "CreditLog"],
  endpoints: builder => ({
    getCredits: builder.query<IGetCreditsResponse, IGetCreditsPayload>({
      query: payload => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: "/v1/dealer_credit",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Credits"],
    }),
    getCreditDetails: builder.query<IGetCreditDetailsResponse, IGetCreditDetailsPayload>({
      query: payload => {
        const { accessToken, credit_id } = payload;
        return {
          url: `/v1/dealer_credit/${credit_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Credit"],
    }),
    getCreditLog: builder.query<IGetCreditLogResponse, IGetCreditLogPayload>({
      query: payload => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: "/v1/dealer_credit_log",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Credits"],
    }),
    getCreditSimulator: builder.query<IGetCreditSimulationResponse, IGetCreditSimulationPayload>({
      query: ({ accessToken, ...params }) => {
        return {
          url: "/v1/dealer_credit_simulator",
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchCreditParams: builder.query<IFetchCreditParamsResponse, IFetchCreditParamsPayload>({
      query: ({ accessToken, credit_id }) => {
        return {
          url: `/v1/dealer_credit/${credit_id}/simulation_params`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    exportCredits: builder.mutation<IExportCreditsResponse, IExportCreditsPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/dealer_credit_summary_export",
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    depreciateCredit: builder.mutation<IDepreciateCreditResponse, IDepreciateCreditPayload>({
      query: ({ accessToken, credit_id, remaining_principal }) => {
        return {
          url: `/v1/dealer_credit/${credit_id}/depreciate`,
          method: "POST",
          body: { remaining_principal },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Credit"],
    }),
    defaultDealerCredit: builder.mutation<IDefaultDealerCreditResponse, IDefaultDealerCreditPayload>({
      query: ({ accessToken, credit_id }) => {
        return {
          url: `/v1/dealer_credit/${credit_id}/default`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Credit"],
    }),
  }),
});

export const {
  useGetCreditsQuery,
  useGetCreditDetailsQuery,
  useGetCreditLogQuery,
  useGetCreditSimulatorQuery,
  useFetchCreditParamsQuery,
  useExportCreditsMutation,
  useDepreciateCreditMutation,
  useDefaultDealerCreditMutation,
} = creditsApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
    IGetKycRenewalsResponse,
    IGetKycRenewalsPayload,
    IGetDealerAccountCreditLimitExpirationResponse,
    IGetDealerAccountCreditLimitExpirationPayload,
    IGetDealerAccountInsuranceExpirationResponse,
    IGetDealerAccountInsuranceExpirationPayload,
    IGetVehicleOwnershipMonitoringResponse,
    IGetVehicleOwnershipMonitoringPayload
} from "./types";

export const riskApi = createApi({
    reducerPath: "riskApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
    tagTypes: ["RiskKycRenewals", "RiskDealerAccountCreditLimitExpirations", "RiskDealerAccountInsuranceExpirations", "RiskVehicleOwnershipMonitoring"],
    endpoints: builder => ({
        getKycRenewals: builder.query<IGetKycRenewalsResponse, IGetKycRenewalsPayload>({
            query: ({accessToken, ...queryParams}) => {
                return {
                    url: `/v1/kyc/upcoming_renewal`,
                    method: "GET",
                    params: queryParams,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["RiskKycRenewals"],
        }),
        getDealerAccountCreditLimitExpiration: builder.query<IGetDealerAccountCreditLimitExpirationResponse, IGetDealerAccountCreditLimitExpirationPayload>({
            query: ({accessToken, ...queryParams}) => {
                return {
                    url: `/v1/dealer_account_credit_limit_expiration`,
                    method: "GET",
                    params: queryParams,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["RiskDealerAccountCreditLimitExpirations"],
        }),
        getDealerAccountInsuranceExpiration: builder.query<IGetDealerAccountInsuranceExpirationResponse, IGetDealerAccountInsuranceExpirationPayload>({
            query: ({accessToken, ...queryParams}) => {
                return {
                    url: `/v1/dealer_account_insurance_expiration`,
                    method: "GET",
                    params: queryParams,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["RiskDealerAccountInsuranceExpirations"],
        }),
        getVehicleOwnershipMonitoring: builder.query<IGetVehicleOwnershipMonitoringResponse, IGetVehicleOwnershipMonitoringPayload>({
            query: ({accessToken, ...queryParams}) => {
                return {
                    url: `/v1/vehicle_ownership/monitoring`,
                    method: "GET",
                    params: queryParams,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["RiskVehicleOwnershipMonitoring"],
        }),

    }),
});

export const {
    useGetKycRenewalsQuery,
    useGetDealerAccountCreditLimitExpirationQuery,
    useGetDealerAccountInsuranceExpirationQuery,
    useGetVehicleOwnershipMonitoringQuery
} = riskApi;

const radio = {
  parts: ["control", "label", "container"],
  baseStyle: {
    label: {
      flex: 1,
    },
    control: {
      w: 4,
      h: 4,
      borderRadius: "full",
      bg: "#fff",
      borderColor: "purple.200",
      _checked: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
      _indeterminate: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
    },
  },
};

export default radio;

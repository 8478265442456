import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateSystemMessagePayload,
  ICreateSystemMessageResponse,
  IDeleteSystemMessagePayload,
  IDeleteSystemMessageResponse,
  IFetchSystemMessagesPayload,
  IFetchSystemMessagesResponse,
  ILookupSystemMessagePayload,
  ILookupSystemMessageResponse,
  IUpdateSystemMessagePayload,
  IUpdateSystemMessageResponse,
} from "./types";

export const systemMessagesApi = createApi({
  reducerPath: "systemMessagesApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["SystemMessages"],
  endpoints: (builder) => ({
    fetchSystemMessages: builder.query<IFetchSystemMessagesResponse, IFetchSystemMessagesPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/system_message`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SystemMessages"],
    }),
    lookupSystemMessage: builder.query<ILookupSystemMessageResponse, ILookupSystemMessagePayload>({
      query: ({ accessToken, message_id }) => {
        return {
          url: `/v1/system_message/${message_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SystemMessages"],
    }),
    createSystemMessage: builder.mutation<ICreateSystemMessageResponse, ICreateSystemMessagePayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/system_message`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SystemMessages"],
    }),
    updateSystemMessage: builder.mutation<IUpdateSystemMessageResponse, IUpdateSystemMessagePayload>({
      query: ({ accessToken, message_id, ...payload }) => {
        return {
          url: `/v1/system_message/${message_id}`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SystemMessages"],
    }),
    deleteSystemMessage: builder.mutation<IDeleteSystemMessageResponse, IDeleteSystemMessagePayload>({
      query: ({ accessToken, message_id }) => {
        return {
          url: `/v1/system_message/${message_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SystemMessages"],
    }),
  }),
});

export const {
  useFetchSystemMessagesQuery,
  useLookupSystemMessageQuery,
  useCreateSystemMessageMutation,
  useUpdateSystemMessageMutation,
  useDeleteSystemMessageMutation,
} = systemMessagesApi;

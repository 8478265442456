import { EntityMapping } from "./entityMapping";
import { IEntityType } from "./types";

const getIconForEntity = (entityType: IEntityType) => {
  return EntityMapping.get(entityType)?.icon;
};

const getRevisionQueryString = (entity: IEntityType, revisionId: string) => {
  const revisionName = EntityMapping.get(entity)?.revision_query_param;
  return revisionName && revisionId
    ? `?${new URLSearchParams({
        [revisionName]: revisionId,
      }).toString()}`
    : "";
};

const getUrlForEntity = (
  entityType: IEntityType ,
  entityId?: string,
  revision_id?: string
) => {
  const baseUrl = EntityMapping.get(entityType)?.url;

  if (!baseUrl || !entityId) {
    return "";
  }
  let url = `${baseUrl}${entityId}`;

  if (revision_id) {
    let query = getRevisionQueryString(entityType, revision_id);
    url = `${url}${query}`;
  }

  return url;
};

export { getIconForEntity, getRevisionQueryString, getUrlForEntity };

import React from "react";
import { Link as ChakraLink, Flex, Menu, MenuButton, MenuList, Portal } from "@chakra-ui/react";
import { useLocation, Link as ReactRouterLink } from "react-router-dom";
import { SidebarItem, sidebarItems } from "./sidebarItems";
import useTranslate from "../../locale/hooks/useTranslate";
import { Authorize } from "../../permissions";
import Icon from "../Icon";

const SidebarMenuSections = () => {
  const translate = useTranslate();
  const location = useLocation();

  const isActive = (current_page: string): boolean => {
    return location.pathname.startsWith(current_page);
  };

  const getVariant = (current_page: string, isSubMenu?: boolean): string => {
    if (isSubMenu) {
      return isActive(current_page) ? "sidebarMenuSubItemLinkSelected" : "sidebarMenuSubItemLink";
    }
    return isActive(current_page) ? "sidebarLinkSelected" : "sidebarLink";
  };

  const hasSubItemActive = (subItems: SidebarItem[]): boolean => {
    return subItems.some((item: SidebarItem) => {
      return isActive(item.path || "");
    });
  };

  return (
    <>
      {sidebarItems.map((item, index) => {
        if (item.path !== null && item.subItems === undefined) {
          return (
            <Authorize key={index} require={item.permissions !== null ? [...item.permissions] : []} requireAll={false}>
              <ChakraLink as={ReactRouterLink} to={item.path} variant={getVariant(item.path)}>
                <Flex justify="center" w="full">
                  <Icon name={item.icon || ""} />
                </Flex>
              </ChakraLink>
            </Authorize>
          );
        } else if (item.path === null && item.subItems !== undefined) {
          return (
            <Authorize key={index} require={item.permissions !== null ? [...item.permissions] : []} requireAll={false}>
              <Menu variant="sidebar" placement="right" key={index}>
                <MenuButton
                  title={translate(`sidebar.${item.label}`)}
                  bgColor={hasSubItemActive(item.subItems) ? "purple.900" : "initial"}>
                  <Flex justify="center">
                    <Icon name={item.icon || ""} />
                  </Flex>
                </MenuButton>
                <Portal>
                  <MenuList>
                    {item.subItems.map((subItem, subIndex) => {
                      return (
                        <Authorize
                          key={subIndex}
                          require={subItem.permissions !== null ? [...subItem.permissions] : []}>
                          <ChakraLink
                            as={ReactRouterLink}
                            to={subItem.path || "/"}
                            key={subIndex}
                            variant={getVariant(subItem.path || "", true)}>
                            {translate(`sidebar.${subItem.label}`)}
                          </ChakraLink>
                        </Authorize>
                      );
                    })}
                  </MenuList>
                </Portal>
              </Menu>
            </Authorize>
          );
        } else return null;
      })}
    </>
  );
};

export default SidebarMenuSections;

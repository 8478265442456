import React from "react";

type Props = {
  isodatetime: string;
};

const formatDateTime = (isodatetime: string) => {
  return isodatetime.replace("T", " ").replace("Z", "");
};

const formatDate = (isodatetime: string) => {
  return isodatetime.split("T")[0];
};

const DateTimeFormatter = ({ isodatetime }: Props) => {
  return <>{formatDateTime(isodatetime)}</>;
};

const formatLocalDateTimeToUTC = (localDateTime: string) => {
  return new Date(localDateTime).toISOString().replace(".000Z", "");
};

export default DateTimeFormatter;
export { formatDateTime, formatDate, formatLocalDateTimeToUTC };

import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Icon from "./Icon";

type Props = {
  children: JSX.Element;
};

const Layout = ({ children }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLargeScreen] = useMediaQuery("(min-width: 992px)");

  return (
    <Flex h="100%">
      <Drawer isOpen={!isLargeScreen ? isOpen : false} placement="left" onClose={onClose} variant="sidebarDrawer">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton zIndex="2" color="white" />
          <DrawerBody p="0">
            <Sidebar isAccordionMenu={true} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box display={["none", null, null, "block"]}>
        <Sidebar />
      </Box>
      <Flex flex="1" flexDirection="column" height="auto" overflow="auto">
        <Topbar
          leftAction={
            <IconButton
              icon={<Icon name="menu" />}
              aria-label="Open sidebar"
              variant="linkWhite"
              onClick={onOpen}
              _hover={{ textDecoration: "none" }}
              mr="2"
              display={["inline-flex", null, null, "none"]}
            />
          }
        />
        {children}
      </Flex>
    </Flex>
  );
};

export default Layout;

import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Link as ChakraLink,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useLocation, Link as ReactRouterLink } from "react-router-dom";
import { SidebarItem, sidebarItems } from "./sidebarItems";
import useTranslate from "../../locale/hooks/useTranslate";
import { Authorize } from "../../permissions";
import Icon from "../Icon";

const SidebarAccordionSections = () => {
  const translate = useTranslate();
  const location = useLocation();

  const isActive = (current_page: string): boolean => {
    return location.pathname.startsWith(current_page);
  };

  const getVariant = (current_page: string, isSubMenu?: boolean): string => {
    if (isSubMenu) {
      return isActive(current_page) ? "sidebarSectionLinkSelected" : "sidebarSectionLink";
    }
    return isActive(current_page) ? "sidebarLinkSelected" : "sidebarLink";
  };

  const hasSubItemActive = (subItems: SidebarItem[]): boolean => {
    return subItems.some((item: SidebarItem) => {
      return isActive(item.path || "");
    });
  };
  const [panels, setPanels] = useState<Record<string, number>>({});
  const onAccordionChange = (accordion: number, route_index: number) => {
    setPanels((current) => {
      const temp = { ...current };
      temp[route_index] = accordion;
      return temp;
    });
  };

  const resetAccordions = (route_index: number) => {
    setPanels({ [route_index]: 0 });
  };
  return (
    <>
      {sidebarItems.map((item, route_index) => {
        if (item.path !== null && item.subItems === undefined) {
          return (
            <Authorize
              key={route_index}
              require={item.permissions !== null ? [...item.permissions] : []}
              requireAll={false}
            >
              <ChakraLink
                as={ReactRouterLink}
                to={item.path}
                variant={getVariant(item.path)}
                onClick={() => resetAccordions(route_index)}
              >
                <Flex gap="2" alignItems="center" w="full">
                  <Icon name={item.icon || ""} />
                  <Text>{translate(`sidebar.${item.label}`)}</Text>
                </Flex>
              </ChakraLink>
            </Authorize>
          );
        } else if (item.path === null && item.subItems !== undefined) {
          return (
            <Authorize
              key={route_index}
              require={item.permissions !== null ? [...item.permissions] : []}
              requireAll={false}
            >
              <Accordion
                allowToggle
                reduceMotion
                variant="sidebarMenuAccordion"
                // defaultIndex={hasSubItemActive(item.subItems) ? [0] : undefined}
                index={panels[route_index] === 0 || hasSubItemActive(item.subItems) ? 0 : -1}
                onChange={(index) => onAccordionChange(index as number, route_index)}
              >
                <AccordionItem>
                  <AccordionButton>
                    <Flex w="full" align="center" gap="2">
                      <Icon name={item.icon || ""} />
                      <Text>{translate(`sidebar.${item.label}`)}</Text>
                    </Flex>
                  </AccordionButton>

                  <AccordionPanel>
                    {item.subItems.map((subItem, subIndex) => {
                      return (
                        <Authorize
                          key={subIndex}
                          require={subItem.permissions !== null ? [...subItem.permissions] : []}
                        >
                          <ChakraLink
                            as={ReactRouterLink}
                            to={subItem.path || ""}
                            key={subIndex}
                            variant={getVariant(subItem.path || "", true)}
                            onClick={() => resetAccordions(route_index)}
                          >
                            <Text>{translate(`sidebar.${subItem.label}`)}</Text>
                          </ChakraLink>
                        </Authorize>
                      );
                    })}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Authorize>
          );
        } else return null;
      })}
    </>
  );
};

export default SidebarAccordionSections;

import { useContext, useEffect, useState } from "react";
import { InputGroup, Input, Select } from "@chakra-ui/react";
import useTranslate from "../locale/hooks/useTranslate";
import { TenantContext } from "../auth/ProtectedComponent";

type IPhone = {
  prefix: string;
  number: string;
};

type Props = {
  onPhoneChange: (phone: string) => void;
  initialPhone?: string;
};

const PhoneInput = ({ onPhoneChange, initialPhone }: Props) => {
  const tenant = useContext(TenantContext);
  const translate = useTranslate();
  const availablePrefixes = [
    { prefix: "+358", flag: "🇫🇮", tenant: "hf_fi" },
    { prefix: "+46", flag: "🇸🇪", tenant: "hf_se" },
  ];

  const getPrefixAndNumber = (phone: string, availablePrefixes: Record<string, string>[]) => {
    const prefix = phone
      ? availablePrefixes.find((availablePrefix) => phone.startsWith(availablePrefix.prefix))?.prefix ||
        availablePrefixes[0]["prefix"]
      : availablePrefixes[0]["prefix"];

    const number = phone ? phone.split(prefix)[1] : "";
    return { prefix, number };
  };

  const [phone, setPhone] = useState<IPhone>(getPrefixAndNumber(initialPhone || "", availablePrefixes));

  useEffect(() => {
    if (initialPhone) {
      setPhone(getPrefixAndNumber(initialPhone || "", availablePrefixes));
    }else{
      const availablePrefix = availablePrefixes.find((availablePrefix) => availablePrefix.tenant === tenant);
      setPhone({ prefix: availablePrefix?.prefix || availablePrefixes[0]["prefix"], number: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPhone]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const updatePhone = { ...phone, [e.target.name]: e.target.value.trim() };
    setPhone(updatePhone);
    const phoneNumber = updatePhone.number ? `${updatePhone.prefix}${updatePhone.number}` : "";
    if (e.target.value.trim()) {
      onPhoneChange(phoneNumber);
    } else {
      onPhoneChange("");
    }
  };

  return (
    <InputGroup>
      <Select w="28" flexShrink="0" name="prefix" value={phone.prefix} onChange={handlePhoneChange}>
        {availablePrefixes.map((prefix, idx) => (
          <option key={idx} value={prefix.prefix}>
            {`${prefix.flag} ${prefix.prefix}`}
          </option>
        ))}
      </Select>
      <Input
        borderLeftColor="transparent"
        type="tel"
        name="number"
        placeholder={translate("placeholders.phone")}
        value={phone.number}
        onChange={handlePhoneChange}
      />
    </InputGroup>
  );
};

export default PhoneInput;

import React from "react";
import { IEntityType } from "../types";
import { getIconForEntity } from "../entity";
import Icon from "../../shared/Icon";
import { Text } from "@chakra-ui/react";

type Props = {
  entityType: IEntityType;
};

const EntityIcon = ({ entityType }: Props) => {
  const iconName = getIconForEntity(entityType);
  return (
    <>
      {iconName && <Icon name={iconName} mr="1" />}
      {!iconName && <Text>{entityType}: &nbsp;</Text>}
    </>
  );
};

export default EntityIcon;

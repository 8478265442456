import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { repossessionsApi } from "../repossessions/service";

import {
  IVehicleResponse,
  IVehicleFetchPayload,
  IVehiclesPayload,
  IVehiclesResponse,
  ICreateVehiclePayload,
  ICreateVehicleResponse,
  IEditVehiclePayload,
  IEditVehicleResponse,
  IDeleteVehiclePayload,
  IValuation,
  IGetValuationsPayload,
  IGetValuationsResponse,
  IGetValuationPayload,
  IPostValuationPayload,
  IPatchValuationPayload,
  IDeleteValuationPayload,
  IGetActiveValuationPayload,
  IImportVehicleResponse,
  IImportVehiclePayload,
  IRequestValuationPayload,
  IRequestValuationResponse,
} from "./types";

export const vehicleApi = createApi({
  reducerPath: "vehicleApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Vehicle", "Valuation"],
  endpoints: (builder) => ({
    getVehicle: builder.query<IVehicleResponse, IVehicleFetchPayload>({
      query: (payload) => {
        const { accessToken, vehicle_id } = payload;
        return {
          url: `/v1/vehicle/${vehicle_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Vehicle"],
    }),
    getVehicles: builder.query<IVehiclesResponse, IVehiclesPayload>({
      query: (payload) => {
        const { accessToken, ...query } = payload;
        return {
          url: `/v1/vehicle`,
          params: query,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Vehicle"],
    }),
    createVehicle: builder.mutation<ICreateVehicleResponse, ICreateVehiclePayload>({
      query: ({ accessToken, payload }) => {
        return {
          url: `/v1/vehicle`,
          method: "POST",
          body: { vehicle: payload },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    editVehicle: builder.mutation<IEditVehicleResponse, IEditVehiclePayload>({
      query: (payload) => {
        const { accessToken, vehicle, revision_id } = payload;
        const { vehicle_id, ...body } = vehicle;
        return {
          url: `/v1/vehicle/${vehicle_id}`,
          method: "PUT",
          body: {
            vehicle_id,
            revision_id,
            vehicle: body,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    deleteVehicle: builder.mutation<{ updated: boolean }, IDeleteVehiclePayload>({
      query: ({ vehicleId, accessToken }) => {
        return {
          url: `/v1/vehicle/${vehicleId}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
    getValuations: builder.query<IGetValuationsResponse, IGetValuationsPayload>({
      query: ({ accessToken, vehicle_id, ...query }) => {
        return {
          url: `/v1/vehicle/${vehicle_id}/valuation`,
          method: "GET",
          params: query,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Valuation"],
    }),
    getValuation: builder.query<IValuation, IGetValuationPayload>({
      query: ({ accessToken, vehicle_id, valuation_id }) => {
        return {
          url: `/v1/vehicle/${vehicle_id}/valuation/${valuation_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Valuation"],
    }),
    getActiveValuation: builder.query<IValuation, IGetActiveValuationPayload>({
      query: ({ accessToken, vehicle_id }) => {
        return {
          url: `/v1/vehicle/${vehicle_id}/active_valuation`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Valuation"],
    }),
    postValuation: builder.mutation<{ valuation_id: string }, IPostValuationPayload>({
      query: ({ accessToken, vehicle_id, ...payload }) => {
        return {
          url: `/v1/vehicle/${vehicle_id}/valuation`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: () => {
        import("../store").then(({ store }) => {
          //used when valuation is added from repossessions details page
          store.dispatch(repossessionsApi.util.invalidateTags(["Repossessions"]));
        });
        return ["Valuation"];
      },
    }),
    requestValuation: builder.mutation<IRequestValuationResponse, IRequestValuationPayload>({
      query: ({ accessToken, vehicle_id, mileage_km }) => {
        return {
          url: `/v1/vehicle/${vehicle_id}/request_valuation`,
          method: "POST",
          body: { mileage_km },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Valuation"],
    }),
    patchValuation: builder.mutation<{ updated: boolean }, IPatchValuationPayload>({
      query: ({ accessToken, vehicle_id, valuation_id, ...payload }) => {
        return {
          url: `/v1/vehicle/${vehicle_id}/valuation/${valuation_id}`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Valuation"],
    }),
    deleteValuation: builder.mutation<{ updated: boolean }, IDeleteValuationPayload>({
      query: ({ accessToken, vehicle_id, valuation_id }) => {
        return {
          url: `/v1/vehicle/${vehicle_id}/valuation/${valuation_id}`,
          method: "Delete",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Valuation"],
    }),
    importVehicle: builder.mutation<IImportVehicleResponse, IImportVehiclePayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/vehicle_import",
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Vehicle"],
    }),
  }),
});

export const {
  useGetVehicleQuery,
  useGetVehiclesQuery,
  useCreateVehicleMutation,
  useEditVehicleMutation,
  useDeleteVehicleMutation,
  useGetValuationsQuery,
  useGetValuationQuery,
  useGetActiveValuationQuery,
  usePostValuationMutation,
  usePatchValuationMutation,
  useDeleteValuationMutation,
  useImportVehicleMutation,
  useRequestValuationMutation,
} = vehicleApi;

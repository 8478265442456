import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import {
    IGetArosInvoicesPayload,
    IGetArosInvoicesResponse,
    IGetArosOrganisationsPayload,
    IGetArosOrganisationsResponse
} from "./types";

export const arosApi = createApi({
    reducerPath: "arosApi",
    baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
    tagTypes: ["ArosInvoices", "ArosOrganisations"],
    endpoints: (builder) => ({
        getArosOrganisations: builder.query<
            IGetArosOrganisationsResponse,
            IGetArosOrganisationsPayload
        >({
            query: ({ accessToken, ...queryParams }) => {
                return {
                    url: "/v1/integration/aros/organisation",
                    method: "GET",
                    params: queryParams,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["ArosOrganisations"],
        }),
        getArosInvoices: builder.query<
            IGetArosInvoicesResponse,
            IGetArosInvoicesPayload
        >({
            query: ({ accessToken, ...queryParams }) => {
                return {
                    url: "/v1/integration/aros/invoice",
                    method: "GET",
                    params: queryParams,
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };
            },
            providesTags: ["ArosInvoices"],
        }),
    }),
});

export const {
    useGetArosOrganisationsQuery,
    useGetArosInvoicesQuery
} = arosApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import {
  IDomainEntitySearchResults,
  IDomainEntitySearchPayload,
  IEntityAttributesResults,
  IEntityAttributesPayload,
} from "./types";

export const domainEntitySearchApi = createApi({
  reducerPath: "domainEntitySearchApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getSearchResults: builder.query<IDomainEntitySearchResults, IDomainEntitySearchPayload>({
      query: ({ accessToken, ...params }) => {
        return {
          url: "/v1/search",
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getEntityAttributes: builder.mutation<IEntityAttributesResults, IEntityAttributesPayload>({
      query: ({ accessToken, entities }) => {
        return {
          url: `/v1/domain_entity_batch_lookup`,
          method: "POST",
          body: { entities },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetSearchResultsQuery, useGetEntityAttributesMutation } = domainEntitySearchApi;

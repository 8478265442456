import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IAlertBatchLookupPayload,
  IAlertBatchLookupResponse,
  IAlertFetchPayload,
  IAlertResponse,
  IAlertsFetchPayload,
  IAlertsResponse,
  IAlertTypesFetchPayload,
  IAlertTypesFetchResponse,
  ICreateAlertPayload,
  ICreateAlertResponse,
  IExportAlertsPayload,
  IExportAlertsResponse,
  IGetAlertCountPayload,
  ISnoozeAlertPayload,
  ISnoozeAlertResponse,
  IUpdateAlertPayload,
  IUpdateAlertResponse,
} from "./types";

export const alertApi = createApi({
  reducerPath: "alertApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Alert"],
  endpoints: (builder) => ({
    getAlert: builder.query<IAlertResponse, IAlertFetchPayload>({
      query: (payload) => {
        const { accessToken, alert_id } = payload;
        return {
          url: `/v1/alert/${alert_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Alert"],
    }),
    getAlerts: builder.query<IAlertsResponse, IAlertsFetchPayload>({
      query: (payload) => {
        const { accessToken, ...query } = payload;
        return {
          url: `/v1/alert`,
          method: "GET",
          params: query,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Alert"],
    }),
    createAlert: builder.mutation<ICreateAlertResponse, ICreateAlertPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/alert`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Alert"],
    }),
    updateAlert: builder.mutation<IUpdateAlertResponse, IUpdateAlertPayload>({
      query: ({ accessToken, alert_id, ...payload }) => {
        return {
          url: `/v1/alert/${alert_id}`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Alert"],
    }),
    getAlertCount: builder.query<{ count: number }, IGetAlertCountPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/alert_count`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Alert"],
    }),
    alertBatchLookup: builder.mutation<IAlertBatchLookupResponse, IAlertBatchLookupPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/alert_batch_lookup`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    fetchAlertTypes: builder.query<IAlertTypesFetchResponse, IAlertTypesFetchPayload>({
      query: ({ accessToken }) => {
        return {
          url: `/v1/alert_types`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Alert"],
    }),
    exportAlerts: builder.mutation<IExportAlertsResponse, IExportAlertsPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/alert_export`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    snoozeAlert: builder.mutation<ISnoozeAlertResponse, ISnoozeAlertPayload>({
      query: ({ accessToken, alertId, ...payload }) => {
        return {
          url: `/v1/alert/${alertId}/snooze`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Alert"],
    }),
  }),
});

export const {
  useGetAlertQuery,
  useGetAlertsQuery,
  useCreateAlertMutation,
  useUpdateAlertMutation,
  useGetAlertCountQuery,
  useAlertBatchLookupMutation,
  useFetchAlertTypesQuery,
  useExportAlertsMutation,
  useSnoozeAlertMutation,
} = alertApi;

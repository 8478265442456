import React, { useState } from "react";
import { Box, Flex, IconButton, Input, Select } from "@chakra-ui/react";
import { DealerNotificationChannel, INotificationListener } from "../types";
import Icon from "../../shared/Icon";
import useTranslate from "../../locale/hooks/useTranslate";
import PhoneInput from "../../shared/PhoneInput";

type Props = {
  listener: INotificationListener;
  removeListener: (localListener: INotificationListener) => void;
  onListenerChange: (localListener: INotificationListener) => void;
};

const NotificationListener = ({ listener, removeListener, onListenerChange }: Props) => {
  const translate = useTranslate();
  const [localListener, setListener] = useState(listener);

  const [isEditing, setIsEditing] = useState(listener.channel === undefined ? true : false);

  const handleChannelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedChannel = e.target.value as DealerNotificationChannel;
    const newListener = { ...localListener, channel: selectedChannel };
    if (selectedChannel === DealerNotificationChannel.EMAIL) {
      newListener.phone_no = undefined;
    } else if (selectedChannel === DealerNotificationChannel.SMS) {
      newListener.email = undefined;
    } else if (selectedChannel === undefined) {
      newListener.email = undefined;
      newListener.phone_no = undefined;
    }
    setListener(newListener);
  };

  const handleInputChange = (value: string, field: string) => {
    setListener({ ...localListener, [field]: value });
  };

  const handleStartEditing = () => {
    setListener(listener);
    setIsEditing(true);
  };

  const handleCancel = () => {
    if (!listener.channel) {
      removeListener(listener);
    }
    setIsEditing(false);
  };

  const handleSaveChanges = () => {
    onListenerChange(localListener);
    setIsEditing(false);
  };

  const handleDelete = () => {
    removeListener(localListener);
  };

  return (
    <Box key={listener.guid} mt="2">
      {!isEditing && (
        <Flex key={listener.guid} alignItems="center" gap="2">
          <Icon name={localListener.channel === DealerNotificationChannel.EMAIL ? "email" : "phone"} color="gray.500" />
          <Box w="1px" h="6" bgColor="gray.300" />
          <Box>{listener.email || listener.phone_no}</Box>
          <Box w="1px" h="6" bgColor="gray.300" ml="auto" />

          <IconButton
            type="button"
            variant="ghostSecondary"
            borderRadius="md"
            aria-label={translate("dealer_account.notifications.edit_listener")}
            icon={<Icon name="edit" />}
            onClick={handleStartEditing}
          />
          <Box w="1px" h="6" bgColor="gray.300" />
          <IconButton
            borderRadius="md"
            type="button"
            variant="ghostAdditionalAlt"
            aria-label={translate("dealer_account.notifications.remove_listener")}
            icon={<Icon name="delete" />}
            onClick={handleDelete}
          />
        </Flex>
      )}

      {isEditing && (
        <Flex gap="2">
          <Flex flex="1 1 60%">
            <Select
              flex="1 1 20%"
              borderRightColor={localListener.channel ? "transparent" : "gray.300"}
              placeholder={translate("dealer_account.notifications.select_channel")}
              value={localListener.channel}
              onChange={handleChannelChange}>
              {Object.values(DealerNotificationChannel).map(channel => {
                return (
                  <option key={channel} value={channel}>
                    {translate(`dealer_account.notifications.channel_options.${channel}`)}
                  </option>
                );
              })}
            </Select>
            {localListener.channel === DealerNotificationChannel.EMAIL && (
              <Input
                flex="1 1 60%"
                placeholder={translate("placeholders.email")}
                value={localListener.email || ""}
                onChange={e => handleInputChange(e.target.value, "email")}
              />
            )}

            {localListener.channel === DealerNotificationChannel.SMS && (
              <Box flex="1 1 60%">
                <PhoneInput
                  initialPhone={localListener.phone_no}
                  onPhoneChange={phone => handleInputChange(phone, "phone_no")}
                />
              </Box>
            )}
          </Flex>

          <IconButton
            ml="auto"
            borderRadius="md"
            isDisabled={!localListener.email && !localListener.phone_no}
            type="button"
            variant="ghostAdditional"
            aria-label={translate("dealer_account.notifications.add_listener")}
            icon={<Icon name="done" />}
            onClick={handleSaveChanges}
          />
          <Box w="1px" h="6" bgColor="gray.300" />
          <IconButton
            borderRadius="md"
            type="button"
            variant="ghostAdditionalAlt"
            aria-label={translate("placeholders.cancel")}
            icon={<Icon name="close" />}
            onClick={handleCancel}
          />
        </Flex>
      )}
    </Box>
  );
};

export default NotificationListener;

import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import { useFetchFeatureFlagsQuery, useFetchUserPermissionQuery } from "../../dealerAccounts/service";
import useTranslate from "../../locale/hooks/useTranslate";
import {
  useCreateSystemMessageMutation,
  useLookupSystemMessageQuery,
  useUpdateSystemMessageMutation,
} from "../service";
import { IFormSystemMessage } from "../types";
import SystemMessageForm from "./SystemMessageForm";

type Props = {
  messageId?: string;
};

const EditSystemMessage = ({ messageId }: Props) => {
  const triggerSubmitRef: any = useRef();
  const accessToken = useContext(AccessTokenContext);
  const translate = useTranslate();
  const toast = useToast();
  const navigate = useNavigate();
  const [updateSystemMessage, { isLoading: updatingMessage }] = useUpdateSystemMessageMutation();
  const [createSystemMessage, { isLoading: creatingMessage }] = useCreateSystemMessageMutation();
  const { data } = useLookupSystemMessageQuery(
    {
      accessToken,
      message_id: messageId || "",
    },
    { skip: !messageId }
  );

  const { data: featureFlags } = useFetchFeatureFlagsQuery({ accessToken });
  const { feature_flags } = featureFlags || { feature_flags: [] };

  const { data: user_permissions } = useFetchUserPermissionQuery({ accessToken });
  const { permissions } = user_permissions || { permissions: [] };

  const triggerSubmit = () => {
    triggerSubmitRef.current.submitForm();
  };

  const onSubmit = (message: IFormSystemMessage) => {
    if (messageId) {
      updateSystemMessage({ accessToken, message_id: messageId, ...message })
        .unwrap()
        .then((response) => {
          const isUpdated = response.updated;
          if (isUpdated) {
            toast({
              title: translate("system_messages.update_success"),
              status: "success",
            });
            navigate(-1);
          }
        })
        .catch(({ data }) => {
          const { message } = data;
          toast({
            title: message ? message : translate("system_messages.update_error"),
            status: "error",
          });
        });
    } else {
      createSystemMessage({ accessToken, ...message })
        .unwrap()
        .then((response) => {
          const newMessageId = response.message_id;
          if (newMessageId) {
            toast({
              title: translate("system_messages.create_success"),
              status: "success",
            });
            navigate(`/system-message/${newMessageId}`);
          }
        })
        .catch(({ data }) => {
          const { message } = data;
          toast({
            title: message ? message : translate("system_messages.create_error"),
            status: "error",
          });
        });
    }
  };
  return (
    <Box layerStyle="card">
      <Text textStyle="h2" mb="4">
        {messageId ? translate("system_messages.edit_message") : translate("system_messages.create_message")}
      </Text>

      <SystemMessageForm 
      user_permissions={permissions}
      feature_flags={feature_flags}
      message={data} onSubmit={onSubmit} submitRef={triggerSubmitRef} />

      <Flex gap="2" flexDir={{ base: "column", md: "row" }} mt="4">
        <Button
          type="button"
          onClick={triggerSubmit}
          isLoading={updatingMessage || creatingMessage}
          isDisabled={updatingMessage || creatingMessage}
        >
          {translate("placeholders.save")}
        </Button>
        <Button type="button" onClick={() => navigate(-1)} variant="outline">
          {translate("placeholders.cancel")}
        </Button>
      </Flex>
    </Box>
  );
};

export default EditSystemMessage;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IPostTokenPayload } from './types';
import {LOCAL_AUTH_API_BASE_URL} from "../config";

export const localAuthApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: LOCAL_AUTH_API_BASE_URL }),
  endpoints: (builder) => ({
    fetchToken: builder.mutation<{ access_token: string, token_type:string }, IPostTokenPayload>({
      query: ({code, grant_type, code_verifier}) => {
        const params = new URLSearchParams();
        params.append('code', code);
        params.append('grant_type', grant_type);
        params.append('code_verifier', code_verifier);
        return {
          url: `/oauth/token`,
          method: "POST",
          body: params.toString(),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          formData: true,
        };
      },
    }),
  }),
});

export const { useFetchTokenMutation } = localAuthApi;

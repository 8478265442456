import React from "react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Icon from "../shared/Icon";

type Props = {
  toURL?: string;
};

const BackButton = ({ toURL }: Props) => {
  const navigate = useNavigate();

  const handleClick = (url: string | undefined) => {
    url ? navigate(url) : navigate(-1);
  };

  return (
    <Button type="button" p="0" variant="backButton" onClick={() => handleClick(toURL)}>
      <Icon name="chevron_left" />
    </Button>
  );
};

export default BackButton;

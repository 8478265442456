import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IAccountDetails } from "../account/types";
import { IAuthParams } from "../auth/types";
import { API_BASE_URL } from "../config";
import {
  ChangePermissionPayload,
  ICreatePasswordResetTicketPayload,
  ICreatePasswordResetTicketResponse,
  ICreateUserPayload,
  IDeleteInternalUserPayload,
  IInternalUsersPayload,
  IInternalUsersResponse,
  IPasswordResetPayload,
  IRolesResponse,
  IUpdateInternalUserPayload,
} from "./types";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["AccountDetails", "Roles"],
  endpoints: (builder) => ({
    createNewInternalUser: builder.mutation<
      { user_id: string },
      ICreateUserPayload
    >({
      query: (payload) => {
        const { accessToken, ...body } = payload;
        return {
          url: "/v1/internal_user",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["AccountDetails"],
    }),
    createPasswordResetTicket: builder.mutation<
      ICreatePasswordResetTicketResponse,
      ICreatePasswordResetTicketPayload
    >({
      query: (payload) => {
        const { accessToken, userId, forwardUrl } = payload;
        return {
          url: `/v1/internal_user/${userId}/password_reset_ticket`,
          method: "POST",
          body: { forward_url: forwardUrl },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    passwordReset: builder.mutation<
      { password_reset_url: string },
      IPasswordResetPayload
    >({
      query: (payload) => {
        const { ticket } = payload;
        return {
          url: "/v1/password_reset",
          method: "POST",
          body: { ticket },
        };
      },
    }),
    getInternalUsers: builder.query<
      IInternalUsersResponse,
      IInternalUsersPayload
    >({
      query: (payload) => {
        const { accessToken, ...query } = payload;
        return {
          url: "/v1/internal_user",
          params: query,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["AccountDetails"],
    }),
    getInternalUser: builder.query<
      { user: IAccountDetails },
      { accessToken: string; userId: string }
    >({
      query: ({ accessToken, userId }) => {
        return {
          url: `/v1/internal_user/${userId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["AccountDetails"],
    }),
    updateInternalUser: builder.mutation<
      { updated: true },
      IUpdateInternalUserPayload
    >({
      query: (payload) => {
        const { accessToken, userId, ...body } = payload;
        return {
          url: `/v1/internal_user/${userId}`,
          method: "PATCH",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["AccountDetails"],
    }),
    deleteInternalUser: builder.mutation<
      { updated: true },
      IDeleteInternalUserPayload
    >({
      query: (payload) => {
        const { accessToken, userId } = payload;
        return {
          url: `/v1/internal_user/${userId}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["AccountDetails"],
    }),
    getInternalUserRoles: builder.query<IRolesResponse, IAuthParams>({
      query: ({ accessToken }) => {
        return {
          url: `/v1/internal_user_role`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getUserGrantedRole: builder.query<
      IRolesResponse,
      { accessToken: string; userId: string }
    >({
      query: ({ accessToken, userId }) => {
        return {
          url: `/v1/internal_user/${userId}/role`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Roles"],
    }),
    grantRoleToUser: builder.mutation<
      { updated: boolean },
      ChangePermissionPayload
    >({
      query: ({ accessToken, userId, roleKey }) => {
        return {
          url: `/v1/internal_user/${userId}/grant_role`,
          method: "POST",
          body: { role_key: roleKey },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Roles"],
    }),
    revokeRoleToUser: builder.mutation<
      { updated: boolean },
      ChangePermissionPayload
    >({
      query: ({ accessToken, userId, roleKey }) => {
        return {
          url: `/v1/internal_user/${userId}/revoke_role`,
          method: "POST",
          body: { role_key: roleKey },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Roles"],
    }),
  }),
});

export const {
  useCreateNewInternalUserMutation,
  useCreatePasswordResetTicketMutation,
  usePasswordResetMutation,
  useGetInternalUsersQuery,
  useGetInternalUserQuery,
  useUpdateInternalUserMutation,
  useDeleteInternalUserMutation,
  useGetInternalUserRolesQuery,
  useGetUserGrantedRoleQuery,
  useGrantRoleToUserMutation,
  useRevokeRoleToUserMutation,
} = adminApi;

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import useTranslate from "../../locale/hooks/useTranslate";

type Props = {
  title: string;
  message: string;
  confirmAction: () => void;
  isLoading?: boolean;
  closeModal?: () => void;
  isOpen?: boolean;
  children?: React.ReactNode;
};

function ConfirmationAlertDialog({ title, message, confirmAction, isLoading, isOpen, closeModal, children }: Props) {
  const translate = useTranslate();
  const [showModal, setModalState] = useState(false);

  useEffect(() => {
    if (isOpen !== undefined && isOpen !== showModal) {
      setModalState(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onModalClose = () => {
    setModalState(false);
    closeModal && closeModal();
  };

  useEffect(() => {
    // Close modal when isLoading returns from true to false
    if (!isLoading && showModal) {
      setModalState(false);
      closeModal && closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      {children && <Box onClick={() => setModalState(true)}>{children}</Box>}
      <Modal isOpen={showModal} onClose={onModalClose} isCentered size="lg" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textStyle="size-lg" mb="4">
              {message}
            </Text>
          </ModalBody>
          <ModalFooter gap="4">
            <Button onClick={onModalClose} variant="ghost">
              {translate("placeholders.cancel")}
            </Button>
            <Button onClick={confirmAction} isLoading={isLoading} isDisabled={isLoading}>
              {translate("placeholders.confirm")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmationAlertDialog;

import { Box, Button, BoxProps, Flex, useDisclosure, Collapse, Text } from "@chakra-ui/react";
import { INotificationListener, INotificationTypesSchema } from "../types";
import Icon from "../../shared/Icon";
import NotificationListener from "./NotificationListener";
import useTranslate from "../../locale/hooks/useTranslate";

type Props = {
  notification: INotificationTypesSchema;
  addListener: (listener: INotificationListener, label: string) => void;
  removeListener: (listener: INotificationListener) => void;
  onListenerChange: (listener: INotificationListener) => void;
  topContainerStyles?: BoxProps;
} & BoxProps;

const NotificationField = ({
  notification,
  addListener,
  removeListener,
  onListenerChange,
  topContainerStyles,
  ...rest
}: Props) => {
  const translate = useTranslate();

  const { isOpen: isNotificationExpanded, onToggle: onToggleCollapse } = useDisclosure();

  const handleAddListener = () => {
    addListener({ channel: undefined }, notification.label);
  };
  const hasNewListener = notification.listeners.find((listener) => listener.channel === undefined);

  const totalEmailListeners = notification.listeners.filter((listener) => listener.channel === "email").length;
  const totalSmsListeners = notification.listeners.filter((listener) => listener.channel === "sms").length;

  return (
    <Box {...rest}>
      <Box
        p="3"
        border="1px solid"
        borderColor="gray.200"
        bgColor={isNotificationExpanded ? "grayWashed.500" : "white"}
        {...topContainerStyles}
      >
        <Flex gap="2" align="center" cursor="pointer" onClick={onToggleCollapse}>
          <Box textStyle="size-lg" fontWeight="medium">
            {translate(
              `dealer_account.notifications.notification_labels.${notification.label}`,
              undefined,
              notification.label
            )}
          </Box>

          <Flex align="center" gap="2" ml="auto">
            {notification.listeners.length > 0 && (
              <Flex px="2" py="0.5" color="purple.800" bgColor="purple.200" borderRadius="lg">
                <Flex gap="1" align="center">
                  <Icon name="email" size="14px" />
                  <Text textStyle="size-xs" fontWeight="medium">
                    {totalEmailListeners}
                  </Text>
                </Flex>

                <Box w="1px" bgColor="purple.500" mx="2" />

                <Flex gap="1" align="center">
                  <Icon name="phone" size="14px" />
                  <Text textStyle="size-xs" fontWeight="medium">
                    {totalSmsListeners}
                  </Text>
                </Flex>
              </Flex>
            )}
            <Icon name={isNotificationExpanded ? "expand_less" : "expand_more"} />
          </Flex>
        </Flex>

        <Collapse in={isNotificationExpanded} animateOpacity>
          <>
            {notification.listeners.map((listener) => {
              return (
                <NotificationListener
                  key={listener.guid}
                  listener={listener}
                  removeListener={removeListener}
                  onListenerChange={onListenerChange}
                />
              );
            })}
          </>

          {!hasNewListener && (
            <Button
              mt="4"
              type="button"
              variant="outlineSecondary"
              size="sm"
              leftIcon={<Icon name="add" />}
              onClick={handleAddListener}
            >
              {translate("placeholders.add")}
            </Button>
          )}
        </Collapse>
      </Box>

      {notification.sub_notification_types.length > 0 && (
        <>
          {notification.sub_notification_types.map((subNotification) => {
            return (
              <NotificationField
                key={subNotification.label}
                notification={subNotification}
                addListener={addListener}
                removeListener={removeListener}
                onListenerChange={onListenerChange}
                ml="8"
                topContainerStyles={{ borderTop: "none" }}
              />
            );
          })}
        </>
      )}
    </Box>
  );
};

export default NotificationField;

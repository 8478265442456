import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { adminApi } from "./admin/service";
import { emailTemplatesApi } from "./emails/service";
import { accountApi } from "./account/service";
import { domainEntitySearchApi } from "./domain-entity/domain-entity-search/service";
import { vehicleApi } from "./vehicle/service";
import { domainEventsApi } from "./domain-events/service";
import { gtxIntegrationApi } from "./integrations/gtx/service";
import { notesApi } from "./notes/service";
import { vinDecoderApi } from "./integrations/vinDecoder/service";
import { filesApi } from "./files/service";
import { arosApi } from "./integrations/aros/service";
import { dealersAccountApi } from "./dealerAccounts/service";
import { contactsApi } from "./contacts/service";
import { dealsApi } from "./deals/service";
import { alertApi } from "./alerts/services";
import { monitoringApi } from "./monitoring/services";
import { siteAuditApi } from "./audits/service";
import { paymentsApi } from "./payments/service";
import { depositPaymentApi } from "./depositPayment/service";
import { externalUsersApi } from "./externalUsers/service";
import { assertionsApi } from "./checks/service";
import { fragusDecoderApi } from "./integrations/fragus/service";
import { carInfoDecoderApi } from "./integrations/carInfo/service";
import { customerInvoicesApi } from "./customerInvoices/service";
import { kycApi } from "./kyc/service";
import { creditsApi } from "./credit/service";
import { ledgerApi } from "./ledger/service";
import { traficomApi } from "./integrations/traficom/service";
import { localAuthApi } from "./auth/service";
import { depositsApi } from "./deposits/service";
import { repossessionsApi } from "./repossessions/service";
import { externalUsersOnboardingApi } from "./externalUsersOnboarding/service";
import { riskApi } from "./risk/service";
import { systemMessagesApi } from "./systemMessages/service";
import { loansApi } from "./loans/service";
import { vehicleMakeModelApi } from "./vehicleMakeModel/service";

export const store = configureStore({
  reducer: {
    [adminApi.reducerPath]: adminApi.reducer,
    [emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [domainEntitySearchApi.reducerPath]: domainEntitySearchApi.reducer,
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [domainEventsApi.reducerPath]: domainEventsApi.reducer,
    [gtxIntegrationApi.reducerPath]: gtxIntegrationApi.reducer,
    [notesApi.reducerPath]: notesApi.reducer,
    [vinDecoderApi.reducerPath]: vinDecoderApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    [arosApi.reducerPath]: arosApi.reducer,
    [dealersAccountApi.reducerPath]: dealersAccountApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [dealsApi.reducerPath]: dealsApi.reducer,
    [alertApi.reducerPath]: alertApi.reducer,
    [monitoringApi.reducerPath]: monitoringApi.reducer,
    [siteAuditApi.reducerPath]: siteAuditApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [depositPaymentApi.reducerPath]: depositPaymentApi.reducer,
    [externalUsersApi.reducerPath]: externalUsersApi.reducer,
    [assertionsApi.reducerPath]: assertionsApi.reducer,
    [fragusDecoderApi.reducerPath]: fragusDecoderApi.reducer,
    [carInfoDecoderApi.reducerPath]: carInfoDecoderApi.reducer,
    [customerInvoicesApi.reducerPath]: customerInvoicesApi.reducer,
    [kycApi.reducerPath]: kycApi.reducer,
    [creditsApi.reducerPath]: creditsApi.reducer,
    [ledgerApi.reducerPath]: ledgerApi.reducer,
    [traficomApi.reducerPath]: traficomApi.reducer,
    [localAuthApi.reducerPath]: localAuthApi.reducer,
    [depositsApi.reducerPath]: depositsApi.reducer,
    [repossessionsApi.reducerPath]: repossessionsApi.reducer,
    [externalUsersOnboardingApi.reducerPath]: externalUsersOnboardingApi.reducer,
    [riskApi.reducerPath]: riskApi.reducer,
    [systemMessagesApi.reducerPath]: systemMessagesApi.reducer,
    [loansApi.reducerPath]: loansApi.reducer,
    [vehicleMakeModelApi.reducerPath]: vehicleMakeModelApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      adminApi.middleware,
      emailTemplatesApi.middleware,
      accountApi.middleware,
      domainEntitySearchApi.middleware,
      vehicleApi.middleware,
      domainEventsApi.middleware,
      gtxIntegrationApi.middleware,
      notesApi.middleware,
      vinDecoderApi.middleware,
      filesApi.middleware,
      arosApi.middleware,
      dealersAccountApi.middleware,
      contactsApi.middleware,
      dealsApi.middleware,
      alertApi.middleware,
      monitoringApi.middleware,
      siteAuditApi.middleware,
      paymentsApi.middleware,
      depositPaymentApi.middleware,
      externalUsersApi.middleware,
      assertionsApi.middleware,
      fragusDecoderApi.middleware,
      carInfoDecoderApi.middleware,
      customerInvoicesApi.middleware,
      kycApi.middleware,
      creditsApi.middleware,
      ledgerApi.middleware,
      traficomApi.middleware,
      localAuthApi.middleware,
      depositsApi.middleware,
      repossessionsApi.middleware,
      externalUsersOnboardingApi.middleware,
      riskApi.middleware,
      systemMessagesApi.middleware,
      loansApi.middleware,
      vehicleMakeModelApi.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

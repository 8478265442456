import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetIdentityVerificationProcessesPayload,
  IGetIdentityVerificationProcessesResponse,
  IGetIdentityVerificationProcessResponse,
  IGetIdentityVerificationProcessPayload,
  IApproveUserIdentityVerificationResponse,
  IApproveUserIdentityVerificationPayload,
  IRejectUserIdentityVerificationPayload,
  IRejectUserIdentityVerificationResponse,
  IDeleteUserIdentityVerificationPayload,
  IDeleteUserIdentityVerificationResponse,
  IGetOrphanExternalUsersResponse,
  IGetOrphanExternalUsersPayload,
} from "./types";

export const externalUsersOnboardingApi = createApi({
  reducerPath: "externalUsersOnboardingApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["IdentityVerificationProcesses"],
  endpoints: builder => ({
    getOrphanExternalUsers: builder.query<IGetOrphanExternalUsersResponse, IGetOrphanExternalUsersPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/orphan_external_user`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getIdentityVerificationProcesses: builder.query<
      IGetIdentityVerificationProcessesResponse,
      IGetIdentityVerificationProcessesPayload
    >({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/external_user_identity_verification`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["IdentityVerificationProcesses"],
    }),
    getIdentityVerificationProcess: builder.query<
      IGetIdentityVerificationProcessResponse,
      IGetIdentityVerificationProcessPayload
    >({
      query: ({ accessToken, process_id }) => {
        return {
          url: `/v1/external_user_identity_verification/${process_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["IdentityVerificationProcesses"],
    }),
    approveUserIdentityVerification: builder.mutation<
      IApproveUserIdentityVerificationResponse,
      IApproveUserIdentityVerificationPayload
    >({
      query: ({ accessToken, process_id }) => {
        return {
          url: `/v1/external_user_identity_verification/${process_id}/approve`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["IdentityVerificationProcesses"],
    }),
    rejectUserIdentityVerification: builder.mutation<
      IRejectUserIdentityVerificationResponse,
      IRejectUserIdentityVerificationPayload
    >({
      query: ({ accessToken, process_id }) => {
        return {
          url: `/v1/external_user_identity_verification/${process_id}/reject`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["IdentityVerificationProcesses"],
    }),
    deleteUserIdentityVerification: builder.mutation<
      IDeleteUserIdentityVerificationResponse,
      IDeleteUserIdentityVerificationPayload
    >({
      query: ({ accessToken, process_id }) => {
        return {
          url: `/v1/external_user_identity_verification/${process_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["IdentityVerificationProcesses"],
    }),
  }),
});

export const {
  useGetOrphanExternalUsersQuery,
  useGetIdentityVerificationProcessesQuery,
  useGetIdentityVerificationProcessQuery,
  useApproveUserIdentityVerificationMutation,
  useRejectUserIdentityVerificationMutation,
  useDeleteUserIdentityVerificationMutation,
} = externalUsersOnboardingApi;

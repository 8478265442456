import { SidebarItem, sidebarItems } from "../../../shared/sidebar/sidebarItems";
import { useState } from "react";
import useTranslate from "../../../locale/hooks/useTranslate";

const useSearchSidebarItems = () => {
  const translate = useTranslate();

  const translatedSidebarItems: SidebarItem[] = sidebarItems.map(item => {
    return {
      ...item,
      label: translate(`sidebar.${item.label}`),
      subItems: item.subItems?.map(subItem => ({
        ...subItem,
        label: translate(`sidebar.${subItem.label}`),
      })),
    };
  });
  const [sidebarSearchResult, setSidebarSearchResult] = useState<SidebarItem[]>([]);

  const searchItems = (query: string): SidebarItem[] => {
    if (query.length > 2) {
      const searchSidebarItems = (items: SidebarItem[]): SidebarItem[] => {
        return items.flatMap(item => {
          if (item.label.toLowerCase().includes(query.toLowerCase())) {
            const validSubItems = (item.subItems || []).filter(subItem => subItem.path);
            return validSubItems.length > 0 ? validSubItems : item.path ? [item] : [];
          }
          if (item.subItems) {
            return searchSidebarItems(item.subItems);
          }
          return [];
        });
      };

      return searchSidebarItems(translatedSidebarItems);
    } else {
      return [];
    }
  };

  const handleSidebarSearch = (value: string) => {
    const searchResults = searchItems(value);
    setSidebarSearchResult(searchResults);
  };

  const resetSidebarSearch = () => {
    setSidebarSearchResult([]);
  };

  return { sidebarSearchResult, handleSidebarSearch, resetSidebarSearch };
};

export default useSearchSidebarItems;

const checkbox = {
  baseStyle: {
    control: {
      w: 4,
      h: 4,
      borderRadius: "2px",
      bg: "#fff",
      borderColor: "purple.200",
      _checked: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
      _indeterminate: {
        bg: "purple.800",
        borderColor: "purple.800",
        color: "white",
        _hover: {
          bg: "purple.900",
          borderColor: "purple.900",
          color: "white",
        },
      },
      _disabled: {
        borderColor: "gray.300",
      },
    },
  },
};

export default checkbox;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetFilesPayload,
  IGetFilesResponse,
  IInitFileUploadPayload,
  IInitFileUploadResponse,
  IFinalizeFileUploadPayload,
  IFinalizeFileUploadResponse,
  ICancelFileUploadPayload,
  ICancelFileUploadResponse,
  IGetFilePayload,
  IFile,
  IDeleteFilePayload,
  IDeleteFileResponse,
  IGetFileLabelsPayload,
  IGetFileLabelsResponse,
  IAddFileLabelPayload,
  IAddFileLabelResponse,
  IDeleteFileLabelPayload,
  IDeleteFileLabelResponse,
  IFetchFilesLabelsResponse,
  IFetchFilesLabelsPayload,
  IFilesBatchLookupPayload,
  IUpdateFileMetadataResponse,
  IUpdateFileMetadataPayload,
} from "./types";

export const filesApi = createApi({
  reducerPath: "filesApi",
  tagTypes: ["Files", "Labels"],
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    getFiles: builder.query<IGetFilesResponse, IGetFilesPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/file",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Files"],
    }),
    getFile: builder.query<IFile, IGetFilePayload>({
      query: ({ accessToken, file_id, ...params }) => {
        return {
          url: `/v1/file/${file_id}`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Files"],
    }),
    deleteFile: builder.mutation<IDeleteFileResponse, IDeleteFilePayload>({
      query: ({ accessToken, file_id }) => {
        return {
          url: `/v1/file/${file_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    updateFileMetadata: builder.mutation<IUpdateFileMetadataResponse, IUpdateFileMetadataPayload>({
      query: payload => {
        const { accessToken, file_id, ...body } = payload;
        return {
          url: `/v1/file/${file_id}`,
          method: "PATCH",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    initFileUpload: builder.mutation<IInitFileUploadResponse, IInitFileUploadPayload>({
      query: ({ accessToken, ...body }) => {
        return {
          url: "/v1/file_upload",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    finalizeFileUpload: builder.mutation<IFinalizeFileUploadResponse, IFinalizeFileUploadPayload>({
      query: ({ accessToken, job_id, etags }) => {
        return {
          url: `/v1/file_upload/${job_id}/finalize`,
          method: "POST",
          body: { etags },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    cancelFileUpload: builder.mutation<ICancelFileUploadResponse, ICancelFileUploadPayload>({
      query: ({ accessToken, job_id }) => {
        return {
          url: `/v1/file_upload/${job_id}/cancel`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    getFileLabels: builder.query<IGetFileLabelsResponse, IGetFileLabelsPayload>({
      query: ({ accessToken, file_id }) => {
        return {
          url: `/v1/file/${file_id}/label`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Labels"],
    }),
    addFileLabel: builder.mutation<IAddFileLabelResponse, IAddFileLabelPayload>({
      query: ({ accessToken, file_id, label }) => {
        return {
          url: `/v1/file/${file_id}/label`,
          method: "PUT",
          body: { label },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Labels", "Files"],
    }),
    deleteFileLabel: builder.mutation<IDeleteFileLabelResponse, IDeleteFileLabelPayload>({
      query: ({ accessToken, file_id, label }) => {
        return {
          url: `/v1/file/${file_id}/label`,
          method: "DELETE",
          body: { label },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Labels", "Files"],
    }),
    getFilesLabels: builder.mutation<IFetchFilesLabelsResponse, IFetchFilesLabelsPayload>({
      query: ({ accessToken, file_ids }) => {
        return {
          url: "/v1/file_label_batch_lookup",
          method: "POST",
          body: { file_ids },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    filesBatchLookup: builder.mutation<IFile[], IFilesBatchLookupPayload>({
      query: ({ accessToken, file_ids }) => {
        return {
          url: "/v1/file_batch_lookup",
          method: "POST",
          body: { file_ids },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      // invalidatesTags: ["Files"],
    }),
  }),
});

export const {
  useGetFilesQuery,
  useGetFileQuery,
  useDeleteFileMutation,
  useUpdateFileMetadataMutation,
  useInitFileUploadMutation,
  useFinalizeFileUploadMutation,
  useCancelFileUploadMutation,
  useGetFileLabelsQuery,
  useAddFileLabelMutation,
  useDeleteFileLabelMutation,
  useGetFilesLabelsMutation,
  useFilesBatchLookupMutation,
} = filesApi;

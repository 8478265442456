const modal = {
  parts: ["header", "overlay", "dialogContainer", "dialog", "closeButton", "body", "footer"],
  baseStyle: {
    dialogContainer: {
      height: "100%",
    },
    dialog: {
      borderRadius: "0",
    },
    overlay: {
      bg: "overlay.500",
    },
    closeButton: {
      top: 0,
      right: 0,
      borderRadius: 0,
      color: "grayMid.500",
      borderBottom: "1px",
      borderLeft: "1px",
      borderColor: "grayLight.500",
    },
    header: {
      p: 4,
      fontSize: "xl",
      lineHeight: "xl",
      fontWeight: "semibold",
      borderBottom: "1px",
      borderColor: "grayLight.500",
    },
    footer: {
      p: 4,
      borderTop: "1px",
      borderColor: "grayLight.500",
    },
    body: {
      p: 4,
    },
  },
};

export default modal;

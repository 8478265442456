import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IFetchLedgerAccountsPayload,
  IFetchLedgerAccountsResponse,
  IFetchLedgerAccountPayload,
  IFetchLedgerAccountResponse,
  IFetchLedgerAccountStatementsPayload,
  IFetchLedgerAccountStatementsResponse,
  IFetchLedgerTransactionsPayload,
  IFetchLedgerTransactionsResponse,
  IFetchLedgerTransactionPayload,
  IFetchLedgerTransactionResponse,
  IDeleteLedgerTransactionPayload,
  IDeleteLedgerTransactionResponse,
  IFetchLedgerAccountPlanPayload,
  IFetchLedgerAccountPlanResponse,
  IAccountBulkLookupResponse,
  IAccountBulkLookupPayload,
} from "./types";

export const ledgerApi = createApi({
  reducerPath: "ledgerApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["LedgerAccounts", "LedgerTransactions", "LedgerAccount", "LedgerTransaction", "LedgerAccountPlan"],
  endpoints: (builder) => ({
    getLedgerAccounts: builder.query<IFetchLedgerAccountsResponse, IFetchLedgerAccountsPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/ledger/account`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["LedgerAccounts"],
    }),
    getLedgerAccount: builder.query<IFetchLedgerAccountResponse, IFetchLedgerAccountPayload>({
      query: ({ accessToken, account_id }) => {
        return {
          url: `/v1/ledger/account/${account_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["LedgerAccount"],
    }),
    getLedgerAccountStatements: builder.query<
      IFetchLedgerAccountStatementsResponse,
      IFetchLedgerAccountStatementsPayload
    >({
      query: ({ accessToken, account_id, ...params }) => {
        return {
          url: `/v1/ledger/account/${account_id}/statement`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["LedgerAccount"],
    }),
    getLedgerTransactions: builder.query<IFetchLedgerTransactionsResponse, IFetchLedgerTransactionsPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/ledger/transaction`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["LedgerTransactions"],
    }),
    getLedgerTransaction: builder.query<IFetchLedgerTransactionResponse, IFetchLedgerTransactionPayload>({
      query: ({ accessToken, transaction_id }) => {
        return {
          url: `/v1/ledger/transaction/${transaction_id}`,
          method: "GET",

          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["LedgerTransaction"],
    }),
    deleteLedgerTransaction: builder.mutation<IDeleteLedgerTransactionResponse, IDeleteLedgerTransactionPayload>({
      query: ({ accessToken, transaction_id }) => {
        return {
          url: `/v1/ledger/transaction/${transaction_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["LedgerTransactions", "LedgerAccount", "LedgerAccountPlan", "LedgerTransaction"],
    }),
    getLedgerAccountPlan: builder.query<IFetchLedgerAccountPlanResponse, IFetchLedgerAccountPlanPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/ledger/account_chart`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["LedgerAccountPlan"],
    }),
    accountBulkLookup: builder.mutation<IAccountBulkLookupResponse, IAccountBulkLookupPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/ledger/account_bulk_lookup",
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetLedgerAccountsQuery,
  useGetLedgerAccountQuery,
  useGetLedgerAccountStatementsQuery,
  useGetLedgerTransactionsQuery,
  useGetLedgerTransactionQuery,
  useDeleteLedgerTransactionMutation,
  useGetLedgerAccountPlanQuery,
  useAccountBulkLookupMutation,
} = ledgerApi;

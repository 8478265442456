import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import { IVehicle } from "../../vehicle/types";
import {
  IImportVehiclesFromGTXPayload,
  IGetGTXAppraisalsPayload,
  IGetGTXAppraisalsResponse,
  IGetGTXAppraisalPayload,
  IGetGTXAppraisalResponse,
} from "./types";

export const gtxIntegrationApi = createApi({
  reducerPath: "gtxIntegrationApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    importVehiclesFromGTX: builder.mutation<{ vehicle: IVehicle }, IImportVehiclesFromGTXPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/integration/gtx/vehicle_import",
          method: "PUT",
          params: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getGTXAppraisals: builder.query<IGetGTXAppraisalsResponse, IGetGTXAppraisalsPayload>({
      query: ({ reg_no, accessToken }) => {
        return {
          url: "/v1/integration/gtx/appraisal",
          method: "GET",
          params: { reg_no },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    getGTXAppraisal: builder.query<IGetGTXAppraisalResponse, IGetGTXAppraisalPayload>({
      query: ({ appraisal_id, accessToken }) => {
        return {
          url: `/v1/integration/gtx/appraisal/${appraisal_id}`,
          method: "GET",

          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useImportVehiclesFromGTXMutation, useGetGTXAppraisalsQuery, useGetGTXAppraisalQuery } =
  gtxIntegrationApi;

import React from "react";
import { useParams } from "react-router-dom";
import { Authorize, Permissions } from "../../permissions";
import DealerNotifications from "../notifications/DealerNotifications";

export default function DealerAccountNotifications() {
  let { account_id = "" } = useParams();

  return (
    <Authorize require={[Permissions.READ_DEAL]} redirectOnDenyTo="/dealer-accounts">
      <DealerNotifications dealerId={account_id} />
    </Authorize>
  );
}

import { IAuthParams } from "../auth/types";
import { IFeatureFlag, HF_tenant_key } from "../dealerAccounts/types";
import { Permissions } from "../permissions";
export enum SystemMessageSeverity {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export enum LanguageId {
  EN = "EN",
  SE = "SE",
  FI = "FI",
}

interface ITexts {
  language_id: LanguageId;
  text: string;
}

interface ISystemMessage {
  message_id: string;
  severity?: SystemMessageSeverity;
  texts: ITexts[];
  enabled?: boolean;
  valid_from?: string;
  valid_to?: string;
  hf_tenant_keys?: HF_tenant_key[];
  feature_flag?: IFeatureFlag | null;
  permission?: Permissions | null;
}

interface IFormSystemMessage extends Omit<ISystemMessage, "message_id"> {
  message_id?: string;
}

interface ISystemMessageRowData extends Omit<ISystemMessage, "enabled" | "hf_tenant_keys"> {
  enabled: string;
  text: JSX.Element | string;
  hf_tenant_keys: string;
}

interface IFetchSystemMessagesPayload extends IAuthParams {
  offset?: number;
  limit?: number;
}

interface IFetchSystemMessagesResponse {
  messages: ISystemMessage[];
  total_count: number;
  offset: number;
  limit: number;
}
interface ILookupSystemMessagePayload extends IAuthParams {
  message_id: string;
}

interface ILookupSystemMessageResponse extends ISystemMessage {}

interface IUpdateSystemMessagePayload extends IAuthParams {
  message_id: string;
  severity?: SystemMessageSeverity;
  texts: ITexts[];
  enabled?: boolean;
  valid_from?: string;
  valid_to?: string;
  hf_tenant_keys?: HF_tenant_key[];
  feature_flag?: IFeatureFlag | null;
  permission?: Permissions | null;
}

interface IUpdateSystemMessageResponse {
  updated: boolean;
}

interface ICreateSystemMessagePayload extends IAuthParams {
  severity?: SystemMessageSeverity;
  texts: ITexts[];
  enabled?: boolean;
  valid_from?: string;
  valid_to?: string;
  hf_tenant_keys?: HF_tenant_key[];
}

interface ICreateSystemMessageResponse {
  message_id: string;
}

interface IDeleteSystemMessagePayload extends IAuthParams {
  message_id: string;
}

interface IDeleteSystemMessageResponse {
  updated: boolean;
}

export type {
  ISystemMessage,
  ISystemMessageRowData,
  IFetchSystemMessagesPayload,
  IFetchSystemMessagesResponse,
  ILookupSystemMessagePayload,
  ILookupSystemMessageResponse,
  IFormSystemMessage,
  IUpdateSystemMessagePayload,
  IUpdateSystemMessageResponse,
  ICreateSystemMessagePayload,
  ICreateSystemMessageResponse,
  IDeleteSystemMessagePayload,
  IDeleteSystemMessageResponse,
};

import { useContext } from "react";
import { Flex, Text, Menu, Button, MenuButton, MenuList, MenuItem, Box } from "@chakra-ui/react";
import { useGetSessionQuery } from "../account/service";
import { AccessTokenContext } from "../auth/ProtectedComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { DomainEntitySearch } from "../domain-entity/domain-entity-search";
import { BASE_URL } from "../config";
import loadMarkerWidget from "../feedback/MarkerWidget";
import Icon from "../shared/Icon";
import { Permissions, Authorize } from "../permissions";
import useTranslate from "../locale/hooks/useTranslate";

type Props = {
  pageTitle?: string;
  leftAction?: JSX.Element | string;
  rightAction?: JSX.Element | string;
};

const Topbar = ({ pageTitle, leftAction, rightAction }: Props) => {
  const translate = useTranslate();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const accessToken = useContext(AccessTokenContext);
  const { data } = useGetSessionQuery({ accessToken });
  if (data) {
    loadMarkerWidget(data?.email, data.name);
  }
  return (
    <Flex as="nav" aria-label="Search area" layerStyle={["topBarWrapper"]}>
      <Box>{leftAction}</Box>

      <DomainEntitySearch />

      <Flex ml="auto" maxW={{ base: "40%", lg: "none" }}>
        <Menu placement="bottom-end" matchWidth>
          <MenuButton as={Button} variant="userAccount">
            <Flex alignItems="center">
              <Icon name="manage_accounts" mr="2" />
              <Text noOfLines={1} display="block">
                {data?.email}
              </Text>
              <Icon name="arrow_drop_down" />
            </Flex>
          </MenuButton>
          <MenuList>
            <Authorize require={[Permissions.READ_OWN_USER, Permissions.UPDATE_OWN_USER]}>
              <MenuItem onClick={_ => navigate("/my-account")}>
                <Icon name="settings" mr="2" />
                <Text>{translate("topbar.my_account")}</Text>
              </MenuItem>
            </Authorize>
            <MenuItem onClick={_ => logout({ returnTo: `${BASE_URL}/logout` })}>
              <Icon name="logout" mr="2" />
              <Text>{translate("topbar.logout")}</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Topbar;

import { useContext } from "react";
import { Flex, Box, Text, Button, Menu, MenuButton, MenuItem, MenuList, useToast } from "@chakra-ui/react";
import { Authorize, Permissions } from "../permissions";
import { AccessTokenContext } from "../auth/ProtectedComponent";
import { useGetSessionQuery, useSelectTenantForOwnAccountMutation } from "../account/service";
import useTranslate from "../locale/hooks/useTranslate";
import { HF_tenant_key } from "../dealerAccounts/types";
import Icon from "../shared/Icon";
import { useNavigate } from "react-router-dom";
import useUrlState from "../hooks/useUrlState";

const HfTenantKeySelector = () => {
  const accessToken = useContext(AccessTokenContext);
  const navigate = useNavigate();
  const translate = useTranslate();
  const toast = useToast();
  const [urlState] = useUrlState({});
  const { hf_tenant_key } = urlState;

  const { data: userSession } = useGetSessionQuery({ accessToken });
  const [updateSelectedTenant] = useSelectTenantForOwnAccountMutation();

  const visibleTenants = userSession?.visible_tenants || [];
  const selectedTenant = hf_tenant_key || userSession?.selected_tenant || null;

  const getTenantKeyIcon = (tenantKey: HF_tenant_key | null) => {
    if (!tenantKey) return "";
    if (tenantKey === "hf_fi") {
      return "🇫🇮";
    } else {
      return "🇸🇪";
    }
  };

  const handleChangeSelectedTenant = (tenantKey: HF_tenant_key) => {
    updateSelectedTenant({ accessToken, tenant_key: tenantKey })
      .unwrap()
      .then(() => {
        navigate("/dashboard");
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: translate("account.select_tenant_error_message"),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  if (visibleTenants.length <= 1 || selectedTenant === null) return null;

  return (
    <Authorize require={[Permissions.READ_TENANT]}>
      <Menu placement="bottom-end">
        <MenuButton as={Button} variant="tenantSelector">
          <Flex alignItems="center" w="full">
            <Box fontSize="xl">{getTenantKeyIcon(selectedTenant)} </Box>
            <Text noOfLines={1} display="block" ml="2">
              {`HF ${translate(`hf_tenant_key.country.${selectedTenant}`)}`}
            </Text>
            <Icon name="expand_more" ml="auto" />
          </Flex>
        </MenuButton>
        <MenuList mt="-9px">
          {visibleTenants.map((tenant) => (
            <Authorize key={tenant} require={[Permissions.SELECT_OWN_TENANT]}>
              <MenuItem minH="40px" onClick={() => handleChangeSelectedTenant(tenant)}>
                <Flex gap="1">
                  <Box fontSize="xl">{getTenantKeyIcon(tenant)}</Box>
                  {translate(`hf_tenant_key.country.${tenant}`)}
                </Flex>
              </MenuItem>
            </Authorize>
          ))}
        </MenuList>
      </Menu>
    </Authorize>
  );
};

export default HfTenantKeySelector;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IAddVehicleMakePayload,
  IAddVehicleMakeResponse,
  IGetVehicleMakePayload,
  IGetVehicleMakeResponse,
  IGetVehicleMakesPayload,
  IGetVehicleMakesResponse,
  IDeleteVehicleMakePayload,
  IDeleteVehicleMakeResponse,
  IGetVehicleModelsPayload,
  IGetVehicleModelsResponse,
  IAddVehicleModelPayload,
  IAddVehicleModelResponse,
  IDeleteVehicleModelPayload,
  IDeleteVehicleModelResponse,
  IEditVehicleMakePayload,
  IEditVehicleMakeResponse,
  IEditVehicleModelPayload,
  IEditVehicleModelResponse,
  IGetVehicleModelPayload,
  IGetVehicleModelResponse,
} from "./types";

export const vehicleMakeModelApi = createApi({
  reducerPath: "vehicleMakeModelApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["VehicleMakes", "VehicleMake", "VehicleModels", "VehicleModel"],
  endpoints: builder => ({
    getVehicleMakes: builder.query<IGetVehicleMakesResponse, IGetVehicleMakesPayload>({
      query: payload => {
        const { accessToken, ...query } = payload;
        return {
          url: `/v1/vehicle_make`,
          params: query,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["VehicleMakes"],
    }),
    addVehicleMake: builder.mutation<IAddVehicleMakeResponse, IAddVehicleMakePayload>({
      query: ({ accessToken, ...params }) => {
        return {
          url: `/v1/vehicle_make`,
          method: "POST",
          body: params,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["VehicleMakes"],
    }),
    getVehicleMake: builder.query<IGetVehicleMakeResponse, IGetVehicleMakePayload>({
      query: payload => {
        const { accessToken, vehicle_make_id } = payload;
        return {
          url: `/v1/vehicle_make/${vehicle_make_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["VehicleMake"],
    }),
    editVehicleMake: builder.mutation<IEditVehicleMakeResponse, IEditVehicleMakePayload>({
      query: ({ accessToken, vehicle_make_id, ...props }) => {
        return {
          url: `/v1/vehicle_make/${vehicle_make_id}`,
          method: "PUT",
          body: props,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["VehicleMake"],
    }),
    deleteVehicleMake: builder.mutation<IDeleteVehicleMakeResponse, IDeleteVehicleMakePayload>({
      query: ({ accessToken, vehicle_make_id }) => {
        return {
          url: `/v1/vehicle_make/${vehicle_make_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["VehicleMakes"],
    }),
    getVehicleModels: builder.query<IGetVehicleModelsResponse, IGetVehicleModelsPayload>({
      query: payload => {
        const { accessToken, make_id, ...query } = payload;
        return {
          url: `/v1/vehicle_make/${make_id}/vehicle_model`,
          params: query,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["VehicleModels"],
    }),
    addVehicleModel: builder.mutation<IAddVehicleModelResponse, IAddVehicleModelPayload>({
      query: payload => {
        const { accessToken, vehicle_make_id, ...props } = payload;
        return {
          url: `/v1/vehicle_make/${vehicle_make_id}/vehicle_model`,
          method: "POST",
          body: props,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["VehicleModels"],
    }),
    getVehicleModel: builder.query<IGetVehicleModelResponse, IGetVehicleModelPayload>({
      query: payload => {
        const { accessToken, vehicle_model_id } = payload;
        return {
          url: `/v1/vehicle_model/${vehicle_model_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["VehicleModel"],
    }),
    editVehicleModel: builder.mutation<IEditVehicleModelResponse, IEditVehicleModelPayload>({
      query: ({ accessToken, vehicle_model_id, ...props }) => {
        return {
          url: `/v1/vehicle_model/${vehicle_model_id}`,
          method: "PUT",
          body: props,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["VehicleModels", "VehicleModel"],
    }),
    deleteVehicleModel: builder.mutation<IDeleteVehicleModelResponse, IDeleteVehicleModelPayload>({
      query: ({ accessToken, vehicle_model_id }) => {
        return {
          url: `/v1/vehicle_model/${vehicle_model_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["VehicleModels"],
    }),
  }),
});

export const {
  useGetVehicleMakesQuery,
  useAddVehicleMakeMutation,
  useGetVehicleMakeQuery,
  useEditVehicleMakeMutation,
  useDeleteVehicleMakeMutation,
  useGetVehicleModelsQuery,
  useAddVehicleModelMutation,
  useGetVehicleModelQuery,
  useEditVehicleModelMutation,
  useDeleteVehicleModelMutation,
} = vehicleMakeModelApi;

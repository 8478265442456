const menu = {
  parts: ["button", "list", "item", "groupTitle", "command", "divider"],
  baseStyle: {
    list: {
      borderRadius: "0",
      padding: "0",
      border: "1px",
      borderColor: "purple.500",
      boxShadow: "base",
      color: "grayDark.500",
      minWidth: "auto",
      zIndex: "1101",
    },
    item: {
      padding: "0 12px",
      height: "40px",
      _hover: {
        bg: "grayLight.500",
      },
    },
  },
  sizes: {
    md: {
      list: {},
    },
  },
  variants: {
    filter: {
      button: {
        display: "flex",
        alignItems: "center",
        h: "40px",
        padding: "8px 12px",
        borderRadius: "0",
        border: "1px",
        borderColor: "gray.300",
        color: "gray.600",
        background: "#fff",
        _hover: {
          borderColor: "purple.500",
        },
        _active: {
          borderColor: "purple.500",
        },
        _disabled: {
          color: "gray.500",
          background: "rgba(197, 197, 197, 0.1)",
          _hover: {
            color: "gray.500",
            borderColor: "gray.300",
          },
        },
      },
      list: {
        shadow: "dropdown",
        maxHeight: "512px",
        overflow: "auto",
      },
      item: {
        _hover: {
          background: "transparent",
        },
      },
    },
    sidebar: {
      button: {
        borderBottom: "0",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "normal",
        justifyContent: "flexStart",
        textAlign: "left",
        px: 3,
        py: 4,
        marginBottom: "2",
        minHeight: "56px",
        height: "auto",
        borderRadius: "10px",
        flexWrap: "wrap",
        bg: "transparent",
        w: "100%",
        _hover: {
          bg: "purple.700",
        },
        _active: {
          bg: "purple.900",
        },
        _expanded: {
          bg: "purple.900",
        },
      },
      list: {
        padding: 3,
        borderRadius: "md",
        border: "none",
        bg: "purple.900",
      },
    },
  },
};

export default menu;

import { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { InputGroup, InputLeftElement, InputRightElement, Input, Box, useOutsideClick } from "@chakra-ui/react";
import Icon from "../../shared/Icon";
import { useDebouncedEffect } from "../../hooks/useDebouncedEffect";
import { useGetSearchResultsQuery } from "./service";
import DomainEntitySearchResult from "./DomainEntitySearchResult";
import { Permissions, Authorize } from "../../permissions";
import { AccessTokenContext } from "../../auth/ProtectedComponent";
import { IDomainEntitySearchResult } from "./types";
import { getUrlForEntity } from "../entity";
import Loader from "../../shared/Loader";
import useTranslate from "../../locale/hooks/useTranslate";
import { SidebarItem } from "../../shared/sidebar/sidebarItems";
import SidebarItemsSearchResult from "../../shared/sidebar/SidebarItemsSearchResult";
import useSearchSidebarItems from "../../shared/sidebar/hooks/useSearchSidebarItems";
import useAltPlusKeyPress from "../../shared/hooks/useAltPlusKeyPress";

enum SearchModeEnum {
  DOMAIN_ENTITY = "domain_entity",
  PAGE = "page",
}

type Props = {
  searchMode?: SearchModeEnum;
};

const DomainEntitySearch = ({ searchMode }: Props) => {
  const translate = useTranslate();
  const accessToken = useContext(AccessTokenContext);
  const navigate = useNavigate();
  const searchGuiRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const [focusedResultIdx, setFocusedResultIdx] = useState(-1);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [internalSearchMode, setInternalSearchMode] = useState<SearchModeEnum>(SearchModeEnum.DOMAIN_ENTITY);

  const effectiveSearchMode = searchMode ?? internalSearchMode;

  const { sidebarSearchResult, handleSidebarSearch, resetSidebarSearch } = useSearchSidebarItems();

  const { data, isFetching } = useGetSearchResultsQuery(
    {
      accessToken,
      query: debouncedQuery,
    },
    { skip: !debouncedQuery }
  );

  const resetSearchResults = () => {
    setQuery("");
    setInputValue("");
    setDebouncedQuery("");
    setFocusedResultIdx(-1);
    resetSidebarSearch();
  };

  const resetSearchToDefault = () => {
    resetSearchResults();
    setInternalSearchMode(SearchModeEnum.DOMAIN_ENTITY);
  };

  useOutsideClick({
    ref: searchGuiRef,
    handler: () => {
      resetSearchToDefault();
    },
  });

  useDebouncedEffect(
    () => {
      setDebouncedQuery(query);
    },
    [query],
    300
  );

  useAltPlusKeyPress("KeyS", () => {
    if (!searchMode) {
      setInternalSearchMode(SearchModeEnum.PAGE);
      resetSearchResults();
      inputRef.current?.focus();
    }
  });

  useAltPlusKeyPress("KeyD", () => {
    if (!searchMode) {
      setInternalSearchMode(SearchModeEnum.DOMAIN_ENTITY);
      resetSearchResults();
      inputRef.current?.focus();
    }
  });

  const navigateToEntityPage = (entity: IDomainEntitySearchResult) => {
    navigate(getUrlForEntity(entity.entity_type, entity.entity_id));
    resetSearchToDefault();
  };
  const resultClickHandler = (result: IDomainEntitySearchResult) => {
    navigateToEntityPage(result);
    resetSearchToDefault();
  };

  const navigateToSidebarItemPath = (result: SidebarItem) => {
    navigate(result.path || "");
    resetSearchToDefault();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setInputValue(value);

    if (effectiveSearchMode === SearchModeEnum.DOMAIN_ENTITY) {
      resetSidebarSearch();
      setQuery(value);
    } else {
      setQuery("");
      handleSidebarSearch(value);
    }
  };

  const handleEnterPress = () => {
    if (effectiveSearchMode === SearchModeEnum.PAGE) {
      if (sidebarSearchResult.length && focusedResultIdx > -1) {
        navigateToSidebarItemPath(sidebarSearchResult[focusedResultIdx]);
      } else return;
    } else {
      if (data && focusedResultIdx > -1) {
        navigateToEntityPage(data.results[focusedResultIdx]);
      } else return;
    }
  };

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown") {
      if (
        (data && focusedResultIdx < data?.results?.length - 1) ||
        (sidebarSearchResult.length && focusedResultIdx < sidebarSearchResult.length - 1)
      ) {
        setFocusedResultIdx(focusedResultIdx + 1);
      }
      e.preventDefault();
    }
    if (e.key === "ArrowUp") {
      if (focusedResultIdx > -1) {
        setFocusedResultIdx(focusedResultIdx - 1);
      }
      e.preventDefault();
    }
    if (e.key === "Escape") {
      resetSearchToDefault();
      e.preventDefault();
    }
    if (e.key === "Enter") {
      handleEnterPress();
      e.preventDefault();
    }
  };

  return (
    <Authorize require={[Permissions.DOMAIN_SEARCH]}>
      <Box position="relative" onKeyDown={handleKeyPressed} ref={searchGuiRef} maxW={{ base: "40%", lg: "none" }}>
        <InputGroup>
          {effectiveSearchMode === SearchModeEnum.DOMAIN_ENTITY && (
            <InputLeftElement pointerEvents="none" children={<Icon name="manage_search" />} />
          )}

          {effectiveSearchMode === SearchModeEnum.PAGE && (
            <InputLeftElement pointerEvents="none" children={<Icon name="dataset_linked" />} />
          )}
          <Input
            ref={inputRef}
            placeholder={
              effectiveSearchMode === SearchModeEnum.DOMAIN_ENTITY
                ? translate("placeholders.search_back_office")
                : translate("placeholders.search_page")
            }
            value={inputValue}
            variant="globalSearch"
            pl="9"
            w="96"
            onChange={handleInputChange}
          />
          {isFetching && <InputRightElement pointerEvents="none" children={<Loader thickness="2px" />} />}
        </InputGroup>
        <>
          {debouncedQuery && (
            <Box layerStyle={["inputResultsList"]}>
              {data && (
                <>
                  {data.results.map((result, idx) => (
                    <DomainEntitySearchResult
                      focused={idx === focusedResultIdx}
                      key={result.entity_id}
                      result={result}
                      onResultClicked={resultClickHandler}
                    />
                  ))}
                </>
              )}
              {data?.results.length === 0 && <Box p="4">{translate("no_results")}</Box>}
            </Box>
          )}
          {sidebarSearchResult.length > 0 && (
            <Box layerStyle="inputResultsList">
              {sidebarSearchResult.map((sidebarItem, idx) => {
                return (
                  <SidebarItemsSearchResult
                    key={idx}
                    item={sidebarItem}
                    focused={idx === focusedResultIdx}
                    onItemClicked={navigateToSidebarItemPath}
                  />
                );
              })}
            </Box>
          )}
        </>
      </Box>
    </Authorize>
  );
};

export default DomainEntitySearch;

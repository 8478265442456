import { useEffect } from "react";

const useAltPlusKeyPress = (targetKeyCode: string, onAltPlusKeyPress: () => void) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.altKey && e.code === targetKeyCode) {
        e.preventDefault();
        onAltPlusKeyPress();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [targetKeyCode, onAltPlusKeyPress]);
};

export default useAltPlusKeyPress;

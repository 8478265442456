// Default theme:
//https://chakra-ui-git-fix-typescript-autocomplete.chakra-ui.vercel.app/docs/theming/theme

import { extendTheme } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";
import themeColors from "./colors";
import themeTextStyles from "./textStyles";
import themeLayerStyles from "./layerStyles";
import themeTypography from "./typography";
import themeComponents from "./components";

const colors = { ...themeColors };

const fonts = {
  heading: "Bw Gradual, sans-serif", //100, 300, 400, 500, 700
  body: "Inter, sans-serif", //400, 500, 600, 700
  mono: "Space Mono, monospace", //400, 700
};

const styles = {
  global: {
    html: {
      overflow: "hidden",
      height: "100%",
    },
    body: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      background: "grayOffWhite.500",
      fontSize: "md",
      lineHeight: "md",
      color: "grayDark.500",
    },
    code: {
      fontFamily: "mono",
    },
    ".chakra-tooltip__arrow-wrapper": {
      width: "4 !important",
      height: "4 !important",
      left: "auto !important",
      right: "3px",
      bottom: "calc(5px * -1) !important",
      transform: "none !important",
    },
    a: {
      color: "orange.700",
      cursor: "pointer",
      fontWeight: "medium",
      outline: "2px solid transparent",
      outlineOffset: "2px",
      _hover: {
        textDecoration: "underline",
      },
      _focusVisible: {
        boxShadow: "outline",
      },
    },
    ".contentEditable": {
      borderRadius: "0px",
      border: "1px solid var(--chakra-colors-grayLight-500)",
      padding: "8px 12px",
      transition: "all 120ms ease-in-out",
      _hover: {
        border: "1px solid var(--chakra-colors-purple-500)",
      },
      _focusVisible: {
        outline: "0px solid transparent",
        border: "1px solid var(--chakra-colors-purple-600)",
      },
    },
  },
};

const shadows = {
  base: "0 4px 20px 0 rgba(186, 186, 186, 0.25), 0 1px 2px 0 rgba(186, 186, 186, 0.06)",
  dropdown: "0px 2px 4px rgba(0, 0, 0, 0.15)",
};

const fontSizes = { ...themeTypography.fontSizes };

const lineHeights = { ...themeTypography.lineHeights };

const fontWeights = { ...themeTypography.fontWeights };

const textStyles = { ...themeTextStyles };

const layerStyles = { ...themeLayerStyles };

//https://chakra-ui.com/docs/components/switch/theming
const Switch: ComponentStyleConfig = { ...themeComponents.switchToggle };

//https://chakra-ui.com/docs/components/tabs/theming
const Tabs: ComponentStyleConfig = { ...themeComponents.tabs };

//https://chakra-ui.com/docs/components/badge/theming
const Badge: ComponentStyleConfig = { ...themeComponents.badge };

//https://chakra-ui.com/docs/components/table/usage
const Table: ComponentStyleConfig = { ...themeComponents.table };

//https://chakra-ui.com/docs/components/textarea/theming
const Textarea: ComponentStyleConfig = { ...themeComponents.textarea };

const Form: ComponentStyleConfig = { ...themeComponents.form };

//https://chakra-ui.com/docs/components/select/theming
const Select: ComponentStyleConfig = { ...themeComponents.select };

//https://chakra-ui.com/docs/components/input/theming
const Input: ComponentStyleConfig = { ...themeComponents.input };

//https://chakra-ui.com/docs/components/menu/theming
const Menu: ComponentStyleConfig = { ...themeComponents.menu };

//https://chakra-ui.com/docs/components/modal/theming
const Modal: ComponentStyleConfig = { ...themeComponents.modal };

//https://chakra-ui.com/docs/components/accordion/theming
const Accordion: ComponentStyleConfig = { ...themeComponents.accordion };

//https://chakra-ui.com/docs/components/tag/theming
const Tag: ComponentStyleConfig = { ...themeComponents.tag };

//https://chakra-ui.com/docs/components/text/usage
const Text = { ...themeComponents.text };

//https://chakra-ui.com/docs/components/checkbox/usage
const Checkbox: ComponentStyleConfig = { ...themeComponents.checkbox };

//https://chakra-ui.com/docs/components/radio/theming
const Radio: ComponentStyleConfig = { ...themeComponents.radio };

//https://chakra-ui.com/docs/components/divider/theming
const Divider = { ...themeComponents.divider };

//https://chakra-ui.com/docs/components/button/theming
const Button = { ...themeComponents.button };

//https://chakra-ui.com/docs/components/link/usage
const Link = { ...themeComponents.link };

//https://chakra-ui.com/docs/components/tooltip/theming
const Tooltip = { ...themeComponents.tooltip };

//https://chakra-ui.com/docs/components/drawer/theming
const Drawer = { ...themeComponents.drawer };

const components = {
  Input,
  Button,
  Menu,
  Select,
  Textarea,
  Form,
  Divider,
  Text,
  Modal,
  Tag,
  Checkbox,
  Radio,
  Accordion,
  Table,
  Badge,
  Tabs,
  Switch,
  Link,
  Tooltip,
  Drawer,
};

const theme = extendTheme({
  colors,
  components,
  fonts,
  styles,
  textStyles,
  fontSizes,
  fontWeights,
  lineHeights,
  shadows,
  layerStyles,
});

export default theme;

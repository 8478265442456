import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../../config";
import {
  ISendOwnershipFileResponse,
  ISendOwnershipFilePayload,
  ITransferOwnershipPayload,
  ITransferOwnershipResponse,
} from "./types";

export const traficomApi = createApi({
  reducerPath: "traficomApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    importOwnershipFile: builder.mutation<ISendOwnershipFileResponse, ISendOwnershipFilePayload>({
      query: ({ accessToken, file_id }) => {
        return {
          url: `/v1/integration/traficom/import_ownership_file/${file_id}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    transferOwnership: builder.mutation<ITransferOwnershipResponse, ITransferOwnershipPayload>({
      query: ({ accessToken, snapshot_id }) => {
        return {
          url: `/v1/integration/traficom/${snapshot_id}/synchronize_hf_ownership`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useImportOwnershipFileMutation, useTransferOwnershipMutation } = traficomApi;

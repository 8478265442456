import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetDepositsPayload,
  IGetDepositsResponse,
  IGetDepositResponse,
  IGetDepositPayload,
  ICreateDepositPayload,
  ICreateDepositResponse,
  IUpdateDepositPayload,
  IUpdateDepositResponse,
  IUpdateDepositStatusPayload,
  IUpdateDepositStatusResponse,
} from "./types";

export const depositsApi = createApi({
  reducerPath: "depositsApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Deposits"],
  endpoints: (builder) => ({
    getDeposits: builder.query<IGetDepositsResponse, IGetDepositsPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/deposit`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deposits"],
    }),
    getDepositDetails: builder.query<IGetDepositResponse, IGetDepositPayload>({
      query: ({ accessToken, deposit_id }) => {
        return {
          url: `/v1/deposit/${deposit_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Deposits"],
    }),
    createDeposit: builder.mutation<ICreateDepositResponse, ICreateDepositPayload>({
      query: ({ accessToken, ...body }) => {
        return {
          url: "/v1/deposit",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deposits"],
    }),
    updateDeposit: builder.mutation<IUpdateDepositResponse, IUpdateDepositPayload>({
      query: ({ accessToken, deposit_id, ...body }) => {
        return {
          url: `/v1/deposit/${deposit_id}`,
          method: "PATCH",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deposits"],
    }),
    approveDeposit: builder.mutation<IUpdateDepositStatusResponse, IUpdateDepositStatusPayload>({
      query: ({ accessToken, deposit_id }) => {
        return {
          url: `/v1/deposit/${deposit_id}/approve`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deposits"],
    }),
    rejectDeposit: builder.mutation<IUpdateDepositStatusResponse, IUpdateDepositStatusPayload>({
      query: ({ accessToken, deposit_id }) => {
        return {
          url: `/v1/deposit/${deposit_id}/reject`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deposits"],
    }),
    processDeposit: builder.mutation<IUpdateDepositStatusResponse, IUpdateDepositStatusPayload>({
      query: ({ accessToken, deposit_id }) => {
        return {
          url: `/v1/deposit/${deposit_id}/process`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deposits"],
    }),
    voidDeposit: builder.mutation<IUpdateDepositStatusResponse, IUpdateDepositStatusPayload>({
      query: ({ accessToken, deposit_id }) => {
        return {
          url: `/v1/deposit/${deposit_id}/void`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Deposits"],
    }),
  }),
});

export const {
  useGetDepositsQuery,
  useGetDepositDetailsQuery,
  useCreateDepositMutation,
  useUpdateDepositMutation,
  useApproveDepositMutation,
  useRejectDepositMutation,
  useProcessDepositMutation,
  useVoidDepositMutation,
} = depositsApi;

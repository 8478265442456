import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ICreatePasswordResetTicketPayload, ICreatePasswordResetTicketResponse, IRole } from "../admin/types";
import { API_BASE_URL } from "../config";
import {
  IGetExternalUsersPayload,
  IGetExternalUsersResponse,
  ICreateExternalUserPayload,
  IExternalUser,
  IGetExternalUserPayload,
  IDeleteExternalUserPayload,
  IListExternalUserRoles,
  IChangeRolePayload,
  IUpdateExternalUserPayload,
  IConnectToDealerResponse,
  IConnectToDealerPayload,
  IGetAllExternalUserRolesPayload,
  IGetAllExternalUserRolesResponse,
} from "./types";

export const externalUsersApi = createApi({
  reducerPath: "externalUsersApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["ExternalUsers", "Roles"],
  endpoints: builder => ({
    getExternalUsers: builder.query<IGetExternalUsersResponse, IGetExternalUsersPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/external_user`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["ExternalUsers"],
    }),
    getExternalUser: builder.query<IExternalUser, IGetExternalUserPayload>({
      query: ({ accessToken, userId }) => {
        return {
          url: `/v1/external_user/${userId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["ExternalUsers"],
    }),
    createExternalUser: builder.mutation<{ user_id: string }, ICreateExternalUserPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/external_user`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["ExternalUsers"],
    }),
    createPasswordResetTicket: builder.mutation<ICreatePasswordResetTicketResponse, ICreatePasswordResetTicketPayload>({
      query: payload => {
        const { accessToken, userId, forwardUrl } = payload;
        return {
          url: `/v1/external_user/${userId}/password_reset_ticket`,
          method: "POST",
          body: { forward_url: forwardUrl },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
    deleteExternalUser: builder.mutation<{ updated: boolean }, IDeleteExternalUserPayload>({
      query: ({ accessToken, userId }) => {
        return {
          url: `/v1/external_user/${userId}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["ExternalUsers"],
    }),
    listRolesForExternalUser: builder.query<{ roles: IRole[] }, IListExternalUserRoles>({
      query: ({ accessToken, userId }) => {
        return {
          url: `/v1/external_user/${userId}/role`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Roles"],
    }),
    grantRoleToUser: builder.mutation<{ updated: boolean }, IChangeRolePayload>({
      query: ({ accessToken, userId, roleKey }) => {
        return {
          url: `/v1/external_user/${userId}/grant_role`,
          method: "POST",
          body: { role_key: roleKey },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Roles"],
    }),
    revokeRoleToUser: builder.mutation<{ updated: boolean }, IChangeRolePayload>({
      query: ({ accessToken, userId, roleKey }) => {
        return {
          url: `/v1/external_user/${userId}/revoke_role`,
          method: "POST",
          body: { role_key: roleKey },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Roles"],
    }),
    updateExternalUser: builder.mutation<{ updated: boolean }, IUpdateExternalUserPayload>({
      query: ({ accessToken, userId, ...payload }) => {
        return {
          url: `/v1/external_user/${userId}`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["ExternalUsers"],
    }),
    connectToDealer: builder.mutation<IConnectToDealerResponse, IConnectToDealerPayload>({
      query: payload => {
        const { accessToken, user_id, dealer_id } = payload;
        return {
          url: `/v1/external_user/${user_id}/connect_to_dealer`,
          method: "POST",
          body: { user_id, dealer_id },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["ExternalUsers"],
    }),
    getAllExternalUserRoles: builder.query<IGetAllExternalUserRolesResponse, IGetAllExternalUserRolesPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: `/v1/external_user_role`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetExternalUsersQuery,
  useGetExternalUserQuery,
  useCreateExternalUserMutation,
  useCreatePasswordResetTicketMutation,
  useDeleteExternalUserMutation,
  useListRolesForExternalUserQuery,
  useGrantRoleToUserMutation,
  useRevokeRoleToUserMutation,
  useUpdateExternalUserMutation,
  useConnectToDealerMutation,
  useGetAllExternalUserRolesQuery,
} = externalUsersApi;

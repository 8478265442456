import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import { IGetDealerDepositsPayload, IGetDealerDepositsResponse, IGetDepositUrlPayload, IGetDepositUrlResponse } from "./types";

export const depositPaymentApi = createApi({
  reducerPath: "depositPaymentApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["DealerDeposit"],
  endpoints: (builder) => ({
    getDepositUrl: builder.mutation<
      IGetDepositUrlResponse,
      IGetDepositUrlPayload
    >({
      query: (payload) => {
        return {
          url: "/v1/integration/zimpler/deposit_url",
          method: "POST",
          body: payload,
        };
      },
    }),
    getDealerDeposit: builder.query<IGetDealerDepositsResponse, IGetDealerDepositsPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/deposit`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["DealerDeposit"],
    })
  }),
});

export const {
  useGetDepositUrlMutation,
  useGetDealerDepositQuery
} = depositPaymentApi;

const link = {
  baseStyle: {
    color: "orangeMain.500",
    fontWeight: "medium",
  },
  variants: {
    inherit: {
      color: "inherit",
      fontWeight: "inherit",
      _hover: {
        color: "inherit",
        fontWeight: "inherit",
        textDecoration: "none",
      },
    },
    sidebarSectionLink: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "12px",
      lineHeight: "normal",
      width: "100%",
      borderRadius: "4px",
      height: "40px",
      padding: "6px 6px 6px 32px",
      cursor: "pointer",
      bg: "transparent",
      transition: "all 240ms ease-in-out",
      color: "white",
      _hover: {
        bg: "purpleMenu.500",
        textDecoration: "none",
      },
      _active: {
        bg: "purple.800",
      },
    },
    sidebarSectionLinkSelected: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "12px",
      lineHeight: "normal",
      width: "100%",
      borderRadius: "4px",
      height: "40px",
      padding: "6px 6px 6px 32px",
      cursor: "pointer",
      bg: "purple.700",
      color: "white",
      _hover: {
        textDecoration: "none",
      },
    },
    sidebarLink: {
      color: "white",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "normal",
      display: "flex",
      padding: "16px 12px",
      minHeight: "56px",
      height: "auto",
      marginBottom: "2",
      borderRadius: "10px",
      flexWrap: "wrap",
      w: "100%",
      _hover: {
        bg: "purple.700",
        textDecoration: "none",
      },
      _active: {
        bg: "purple.900",
      },
    },
    sidebarLinkSelected: {
      color: "white",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "normal",
      display: "flex",
      padding: "16px 12px",
      minHeight: "56px",
      height: "auto",
      marginBottom: "2",
      borderRadius: "10px",
      flexWrap: "wrap",
      w: "100%",
      bg: "purple.900",
      _hover: {
        textDecoration: "none",
      },
    },
    sidebarMenuSubItemLink: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "12px",
      lineHeight: "normal",
      width: "100%",
      borderRadius: "4px",
      height: "40px",
      padding: "10px 12px",
      cursor: "pointer",
      bg: "transparent",
      transition: "all 240ms ease-in-out",
      color: "white",
      _hover: {
        bg: "purpleMenu.500",
        textDecoration: "none",
      },
      _active: {
        bg: "purple.800",
      },
    },
    sidebarMenuSubItemLinkSelected: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "12px",
      lineHeight: "normal",
      width: "100%",
      borderRadius: "4px",
      height: "40px",
      padding: "10px 12px",
      cursor: "pointer",
      bg: "purple.700",
      color: "white",
      _hover: {
        textDecoration: "none",
      },
    },
    tableViewDetails: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      h: "40px",
      color: "orange.700",
      borderRadius: "full",
      _hover: {
        color: "orange.800",
        bg: "rgba(207,57,22,.08)",
        textDecoration: "none",
      },
      _active: {
        color: "orange.900",
        bg: "rgba(181,50,20,.08)",
        textDecoration: "none",
      },
    },
    linkPill: {
      w: "auto",
      flexShrink: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      px: "4",
      py: "2",
      fontSize: "md",
      lineHeight: "md",
      color: "purple.800",
      bg: "purple.200",
      borderRadius: "20px",
      cursor: "pointer",
      _hover: {
        bg: "purple.300",
        textDecoration: "none",
      },
      _active: {
        bg: "purple.400",
        textDecoration: "none",
      },
      _disabled: {
        color: "gray.500",
        bg: "gray.400",
        _hover: {
          color: "gray.500",
          bg: "gray.400",
          textDecoration: "none",
        },
      },
      _selected: {
        color: "white",
        bg: "purple.800",
        _hover: {
          textDecoration: "none",
        },
      },
    },
    tagLink: {
      w: "32px",
      h: "32px",
      flex: "0 0 auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "purple.800",
      bg: "white",
      border: "1px solid",
      borderColor: "purple.800",
      borderRadius: "32px",
      _hover: {
        bg: "purple.300",
        textDecoration: "none",
      },
    },
    truncatedBlock: {
      isTruncated: true,
      noOfLines: 2,
      display: "block",
    },
  },
};

export default link;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateSiteAuditResponse,
  ICreateSiteAuditResponsePayload,
  IPatchSiteAuditPayload,
  IPatchSiteAuditResponse,
  IPatchSiteAuditVehiclePayload,
  IPatchSiteAuditVehicleResponse,
  ISiteAuditFetchPayload,
  ISiteAuditResponse,
  ISiteAuditsFetchPayload,
  ISiteAuditsResponse,
  ISiteAuditVehiclePayload,
  ISiteAuditVehicleResponse,
  ISiteAuditVehiclesPayload,
  ISiteAuditVehiclesResponse,
  IActivateSiteAuditResponsePayload,
  IGetSiteAuditPopulateProgressResponse,
  IGetSiteAuditPopulateProgressPayload,
} from "./types";

export const siteAuditApi = createApi({
  reducerPath: "siteAuditApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["SiteAudit"],
  endpoints: (builder) => ({
    getSiteAudit: builder.query<ISiteAuditResponse, ISiteAuditFetchPayload>({
      query: (payload) => {
        const { accessToken, site_audit_id } = payload;
        return {
          url: `/v1/site_audit/${site_audit_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SiteAudit"],
    }),
    getSiteAudits: builder.query<ISiteAuditsResponse, ISiteAuditsFetchPayload>({
      query: (payload) => {
        const { accessToken, ...query } = payload;
        return {
          url: "/v1/site_audit",
          method: "GET",
          params: query,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SiteAudit"],
    }),
    getSiteAuditVehicles: builder.query<ISiteAuditVehiclesResponse, ISiteAuditVehiclesPayload>({
      query: (payload) => {
        const { accessToken, ...query } = payload;
        return {
          url: "/v1/site_vehicle_audit",
          method: "GET",
          params: query,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SiteAudit"],
    }),
    getSiteAuditVehicle: builder.query<ISiteAuditVehicleResponse, ISiteAuditVehiclePayload>({
      query: (payload) => {
        const { accessToken, vehicle_id } = payload;
        return {
          url: `/v1/site_vehicle_audit/${vehicle_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SiteAudit"],
    }),
    patchSiteAuditVehicle: builder.mutation<IPatchSiteAuditVehicleResponse, IPatchSiteAuditVehiclePayload>({
      query: (payload) => {
        const { accessToken, vehicle_id, ...body } = payload;
        return {
          url: `/v1/site_vehicle_audit/${vehicle_id}`,
          method: "PATCH",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SiteAudit"],
    }),
    patchSiteAudit: builder.mutation<IPatchSiteAuditResponse, IPatchSiteAuditPayload>({
      query: (payload) => {
        const { accessToken, site_audit_id, ...body } = payload;
        return {
          url: `/v1/site_audit/${site_audit_id}`,
          method: "PATCH",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SiteAudit"],
    }),
    createSiteAudit: builder.mutation<ICreateSiteAuditResponse, ICreateSiteAuditResponsePayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/site_audit`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SiteAudit"],
    }),
    activateSiteAudit: builder.mutation<IPatchSiteAuditResponse, IActivateSiteAuditResponsePayload>({
      query: ({ accessToken, site_audit_id }) => {
        return {
          url: `/v1/site_audit/${site_audit_id}/activate`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["SiteAudit"],
    }),
    getSiteAuditPopulateProgress: builder.query<
      IGetSiteAuditPopulateProgressResponse,
      IGetSiteAuditPopulateProgressPayload
    >({
      query: (payload) => {
        const { accessToken, process_id } = payload;
        return {
          url: `/v1/site_audit_populate_progress/${process_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["SiteAudit"],
    }),
  }),
});

export const {
  useGetSiteAuditQuery,
  useGetSiteAuditsQuery,
  useCreateSiteAuditMutation,
  useGetSiteAuditVehiclesQuery,
  useGetSiteAuditVehicleQuery,
  usePatchSiteAuditVehicleMutation,
  usePatchSiteAuditMutation,
  useActivateSiteAuditMutation,
  useGetSiteAuditPopulateProgressQuery,
} = siteAuditApi;

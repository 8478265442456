import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  ICreateNotePayload,
  ICreateNoteResponse,
  IFetchNotesPayload,
  IFetchNotesResponse,
  IFetchNotePayload,
  IFetchNoteResponse,
  IUpdateNotePayload,
  IUpdateNoteResponse,
  IDeleteNotePayload,
  IDeleteNoteResponse,
  IGetNotesCountPayload,
} from "./types";

export const notesApi = createApi({
  reducerPath: "notesApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Notes"],
  endpoints: (builder) => ({
    getNotes: builder.query<IFetchNotesResponse, IFetchNotesPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/note`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Notes"],
    }),
    getNote: builder.query<IFetchNoteResponse, IFetchNotePayload>({
      query: ({ accessToken, note_id }) => {
        return {
          url: `/v1/note/${note_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Notes"],
    }),
    addNote: builder.mutation<ICreateNoteResponse, ICreateNotePayload>({
      query: ({ accessToken, ...body }) => {
        return {
          url: `/v1/note`,
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      // invalidatesTags: ["Notes"],
    }),
    updateNote: builder.mutation<IUpdateNoteResponse, IUpdateNotePayload>({
      query: ({ accessToken, note_id, content }) => {
        return {
          url: `/v1/note/${note_id}`,
          method: "PUT",
          body: { content },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Notes"],
    }),
    deleteNote: builder.mutation<IDeleteNoteResponse, IDeleteNotePayload>({
      query: ({ accessToken, note_id }) => {
        return {
          url: `/v1/note/${note_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["Notes"],
    }),
    getNotesCount: builder.query<{ count: number }, IGetNotesCountPayload>({
      query: ({ accessToken, ...queryParams }) => {
        return {
          url: "/v1/note_count",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["Notes"],
    }),
  }),
});

export const {
  useGetNotesQuery,
  useGetNoteQuery,
  useAddNoteMutation,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
  useGetNotesCountQuery,
} = notesApi;

import { Menu, MenuButton, Flex, MenuList, MenuOptionGroup, MenuItemOption, Box } from "@chakra-ui/react";
import React from "react";
import useTranslate from "../../locale/hooks/useTranslate";
import Icon from "../Icon";

type Props = {
  onChange: (key: string, value: undefined | string | string[]) => void;
  value: string[] | undefined;
  options: string[];
  name: string;
  inputText?: string;
  optionsTranslationPath?: string;
};

export default function MultipleSelect({ onChange, value, options, name, inputText, optionsTranslationPath }: Props) {
  const translate = useTranslate();
  return (
    <Box flex={{ md: "1 1 30%" }}>
      <Menu closeOnSelect={false} variant="filter">
        <MenuButton w="full" type="button">
          <Flex align="center" justify="space-between">
            <Box>
              {inputText || translate("placeholders.selected")} {`(${value?.length || 0})`}
            </Box>
            <Icon name="expand_more" />
          </Flex>
        </MenuButton>
        <MenuList minWidth="240px">
          <MenuOptionGroup value={value} type="checkbox" onChange={(value) => onChange(name, value)}>
            {options.map((option: string, index) => {
              return (
                <MenuItemOption key={index} value={option}>
                  {optionsTranslationPath ? translate(`${optionsTranslationPath}.${option}`) : option}
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
}

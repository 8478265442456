import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IApproveKYCApplicationPayload,
  IApproveKYCApplicationResponse,
  ICreateApplicationPayload,
  ICreateApplicationResponse,
  IDeleteKYCApplicationPayload,
  IDeleteKYCApplicationResponse,
  IExportKYCApplicationPayload,
  IExportKYCApplicationResponse,
  IFetchKYCApplicationsPayload,
  IFetchKYCApplicationsResponse,
  IGetKYCApplicationPayload,
  IGetKYCApplicationResponse,
  IRejectKYCApplicationPayload,
  IRejectKYCApplicationResponse,
  IReopenKYCApplicationPayload,
  IReopenKYCApplicationResponse,
  IUpdateKYCApplicationByPutPayload,
  IUpdateKYCApplicationByPutResponse,
  IUpdateKYCApplicationPayload,
  IUpdateKYCApplicationResponse,
  IPatchKYCApplicationMetaResponse,
  IPatchKYCApplicationMetaPayload,
  IExportKYCApplicationsPayload,
  IExportKYCApplicationsResponse,
} from "./types";

export const kycApi = createApi({
  reducerPath: "kycApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["KYCApplications"],
  endpoints: (builder) => ({
    getKYCApplications: builder.query<IFetchKYCApplicationsResponse, IFetchKYCApplicationsPayload>({
      query: (payload) => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: `/v1/kyc/application`,
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["KYCApplications"],
    }),
    updateKYCApplication: builder.mutation<IUpdateKYCApplicationResponse, IUpdateKYCApplicationPayload>({
      query: ({ accessToken, kyc_application_id, ...payload }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    updateKYCApplicationByPutRequest: builder.mutation<
      IUpdateKYCApplicationByPutResponse,
      IUpdateKYCApplicationByPutPayload
    >({
      query: ({ accessToken, kyc_application_id, ...payload }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    patchKYCApplicationMeta: builder.mutation<IPatchKYCApplicationMetaResponse, IPatchKYCApplicationMetaPayload>({
      query: ({ accessToken, kyc_application_id, ...payload }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}/metadata`,
          method: "PATCH",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    getKYCApplication: builder.query<IGetKYCApplicationResponse, IGetKYCApplicationPayload>({
      query: ({ accessToken, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["KYCApplications"],
    }),
    createApplication: builder.mutation<ICreateApplicationResponse, ICreateApplicationPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: `/v1/kyc/application`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    exportKYCApplication: builder.mutation<IExportKYCApplicationResponse, IExportKYCApplicationPayload>({
      query: ({ accessToken, kyc_application_id, ...payload }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}/export`,
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    reopenKYCApplication: builder.mutation<IReopenKYCApplicationResponse, IReopenKYCApplicationPayload>({
      query: ({ accessToken, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}/reopen`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    approveKYCApplication: builder.mutation<IApproveKYCApplicationResponse, IApproveKYCApplicationPayload>({
      query: ({ accessToken, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}/approve`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    rejectKYCApplication: builder.mutation<IRejectKYCApplicationResponse, IRejectKYCApplicationPayload>({
      query: ({ accessToken, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}/reject`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    deleteKYCApplication: builder.mutation<IDeleteKYCApplicationResponse, IDeleteKYCApplicationPayload>({
      query: ({ accessToken, kyc_application_id }) => {
        return {
          url: `/v1/kyc/application/${kyc_application_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      invalidatesTags: ["KYCApplications"],
    }),
    exportKYCApplications: builder.mutation<IExportKYCApplicationsResponse, IExportKYCApplicationsPayload>({
      query: ({ accessToken, ...payload }) => {
        return {
          url: "/v1/kyc/application/export",
          method: "POST",
          body: payload,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      // invalidatesTags: ["KYCApplications"],
    }),
  }),
});

export const {
  useGetKYCApplicationsQuery,
  useUpdateKYCApplicationMutation,
  useGetKYCApplicationQuery,
  useCreateApplicationMutation,
  useExportKYCApplicationMutation,
  useReopenKYCApplicationMutation,
  useApproveKYCApplicationMutation,
  useRejectKYCApplicationMutation,
  useDeleteKYCApplicationMutation,
  useUpdateKYCApplicationByPutRequestMutation,
  usePatchKYCApplicationMetaMutation,
  useExportKYCApplicationsMutation,
} = kycApi;

const drawer = {
  parts: ["dialog"],
  variants: {
    sidebarDrawer: {
      dialog: {
        maxW: 60,
      },
    },
  },
};

export default drawer;

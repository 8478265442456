import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";

import {
  IGetCustomerInvoicePayload,
  IGetCustomerInvoiceResponse,
  IGetCustomerInvoicesPayload,
  IGetCustomerInvoicesResponse,
} from "./types";

export const customerInvoicesApi = createApi({
  reducerPath: "customerInvoicesApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["CustomerInvoices"],
  endpoints: builder => ({
    getCustomerInvoices: builder.query<IGetCustomerInvoicesResponse, IGetCustomerInvoicesPayload>({
      query: payload => {
        const { accessToken, ...queryParams } = payload;
        return {
          url: "/v1/invoices",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      providesTags: ["CustomerInvoices"],
    }),
    getCustomerInvoice: builder.query<IGetCustomerInvoiceResponse, IGetCustomerInvoicePayload>({
      query: ({ accessToken, invoice_id }) => {
        return {
          url: `/v1/invoices/${invoice_id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
    }),
  }),
});

export const { useGetCustomerInvoicesQuery, useGetCustomerInvoiceQuery } = customerInvoicesApi;

import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import BackButton from "./BackButton";

type Props = {
  pageTitle?: string;
  extra?: React.ReactNode;
  rightButton?: JSX.Element | string;
  showBackButton?: boolean;
  backButtonURL?: string;
};

const ContentTopBar = ({ pageTitle, extra, rightButton, showBackButton = true, backButtonURL }: Props) => {
  return (
    <Flex as="nav" aria-label="Page info" layerStyle={["contentTopBar"]}>
      <Flex alignItems="center" gap="2" flex={{ base: "auto", md: "1" }}>
        {showBackButton && <BackButton toURL={backButtonURL} />}
        <Flex flexWrap="wrap" alignItems="center">
          <Text textStyle="h1" flexShrink="0" minW="0" maxW="full" noOfLines={1} mr="2">
            {pageTitle}
          </Text>
          {extra}
        </Flex>
      </Flex>
      <Flex>{rightButton}</Flex>
    </Flex>
  );
};

export default ContentTopBar;
